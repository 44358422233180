import React, { useState, useEffect } from 'react'
import { shallowEqual, useSelector } from "react-redux";
import { Card, CardBody, CardHeader } from '../../../../_metronic/_partials/controls'
import {
	Button,
	Checkbox,
	FormControlLabel,
	MuiThemeProvider,
	TextField,
	Tooltip,
	createMuiTheme
} from '@material-ui/core'
import { useSkeleton } from '../../../hooks/useSkeleton'
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import Editor from '../../../components/editor/Editor'
import { Col, Row } from 'react-bootstrap';
import { buttonsStyle } from '../../../components/tables/table';
import { Delete } from '@material-ui/icons';
import { alertError } from '../../../../utils/logger';

// Create theme for delete button (red)
const theme = createMuiTheme({
	palette: {
		secondary: {
			main: "#F64E60",
		},
	},
});

function getEmptyDocument() {
	return {
		title: "",
		description: "",
		fileURL: null,
		active: true
	}
}

export default function EditPatientDocument(props) {
	const { data, setOpen, onDelete, onSave, newDocument } = props;
	const [document, setDocument] = useState(getEmptyDocument())
	const [selectedFile, setSelectedFile] = useState(null);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(0);

	const { isLoading: isLoadingData, disableLoading: disableLoadingData, ContentSkeleton } = useSkeleton()
	const user = useSelector(
		(store) => store.authentication?.user,
		shallowEqual
	);

	const [changes, setChanges] = useState(-2);

	useEffect(() => {
		if (data) {
			setDocument(data);
			disableLoadingData();
		} else setDocument(getEmptyDocument()); //Aqui no deberia entrar
	}, [data, disableLoadingData])

	useEffect(() => {
		if (changes <= 0) setChanges(changes+1);
	}, [document, selectedFile])

	const handleChange = (element, lang) => (event) => {
		setDocument({ ...document, [element]: event.target.value })
	}

	const handleChangeEditor = (element, lang, value) => {
		if (lang) {
		if (value === ' ') return
		if (!document[element]) document[element] = {}
		let newText = document[element]
		newText[lang] = value
		setDocument({ ...document, [element]: newText })
		} else setDocument({ ...document, [element]: value })
	}

	if (isLoadingData)
		return <ContentSkeleton />
	else return (
		<>
		<Card>
		<CardHeader title={"Edit document"}>
			<div className='mt-5'>
			<Button
				onClick={() => {
				if (changes > 0) setOpenConfirmDialog(1);
				else setOpen(false);
				}}
				variant="outlined"
				style={{ marginRight: '20px' }}>
				Back
			</Button>
			<Button
				onClick={async () => {
					if (!document?.title) {
						alertError({
							error: "Title is needed",
							customMessage: "Could not save document"
						})
					} else {
						const res = await onSave(document, selectedFile);
						if (res) setOpen(false);
					}
				}}
				variant="outlined"
				color="primary"
				style={{ marginRight: '20px' }}>
				Save document
			</Button>
			<ConfirmDialog
				title={
				"Are you sure you want to go back? You will lose all your changes"
				}
				open={openConfirmDialog === 1}
				setOpen={setOpenConfirmDialog}
				onConfirm={async () => {setOpen(false);}}
			/>
				{!newDocument && user?.role.includes("admin") && (
					<>
					<MuiThemeProvider theme={theme}>
						<Button
						onClick={() => setOpenConfirmDialog(2)}
						variant="outlined"
						color="secondary"
						style={{ marginRight: "20px" }}
						>
						Delete document
						</Button>
						<div
						style={{
							display: "flex",
							flexDirection: "row",
							marginLeft: "auto",
						}}
						></div>
					</MuiThemeProvider>

					<ConfirmDialog
						title={
						"Are you sure you want to delete this document?"
						}
						open={openConfirmDialog === 2}
						setOpen={setOpenConfirmDialog}
						onConfirm={async () => {
							const res = await onDelete(document);
							if (res) setOpen(false);
						}}
					/>
					</>
				)}
			</div>
		</CardHeader>
			<CardBody>
			<TextField
				id={`title`}
				label="Title"
				value={document?.title || ""}
				onChange={handleChange('title', false)}
				InputLabelProps={{
				shrink: true
				}}
				margin="normal"
				variant="standard"
				required
			/>
			<Editor
				body={document?.description || ""}
				setBody={new_body => handleChangeEditor('description', false, new_body)}
				className='max-height'
				placeholder={'Enter document description here...'}
				name="Description"
			/>
			<br /><br />
			<Row>
				<Col>				
				<label htmlFor={"upload"} className="ml-0">
					<input
						style={{ display: "none" }}
						id={"upload"}
						name={"upload"}
						type="file"
						accept={"/*"}
						onChange={(e) => {
							setSelectedFile(e.target.files[0]);
						}}
					/>
					<Button
						style={{ marginRight: "15px" }}
						color="secondary"
						component="span"
						variant="outlined"
					>
						{selectedFile ||
						(document?.fileURL&&
							document?.fileURL!== "")
							? "Change file"
							: "Upload file"}
					</Button>
				</label>
				{(selectedFile ||
					(document?.fileURL &&
						document?.fileURL !== "")) && (
					<>
						<span>
							{selectedFile
								? selectedFile?.name
								: document?.fileURL &&
									document?.fileURL !== ""
								? document?.fileURL?.split(
										/-(.*)/s
									)[1]
								: ""}
						</span>
						<Tooltip title={"Delete file"}>
							<Button
								size="small"
								onClick={() => {
									setSelectedFile(null);
									setDocument({
										...document,
										fileURL: null
									})
								}}
								style={{
									...buttonsStyle,
									marginRight: "15px",
								}}
							>
								<Delete />
							</Button>
						</Tooltip>
					</>
				)}
				</Col>
			</Row>
			<FormControlLabel
				control={
				<Checkbox
					checked={document?.active}
					onChange={() =>
					setDocument({
						...document,
						active: !document?.active,
					})
					}
					name="checkActive"
				/>
				}
				label="Active"
			/>
			</CardBody>
		</Card>
		</>
	);
}
