import { getFoodById } from "../api/food/index"

export function foodCalcs(food) {
    const grams = parseFloat(food?.grams) || 0; 

    return {
        kcals: food?.food?.kcals*grams/100,
        proteins: food?.food?.proteins*grams/100,
		carbohydrates: food?.food?.carbohydrates*grams/100,
		fats: food?.food?.fats*grams/100,
		fiber: food?.food?.fiber*grams/100
    }
}

export function valueCalc(value, grams) {
    return parseFloat(value)*parseFloat(grams)/100;
}

export async function recipeCalcs(recipe) {
    const foodsIds = recipe.foods;

    let datt = [];
    let foods = [];

    for (let i = 0; i < foodsIds?.length; ++i) {
        const promise = getFoodById(foodsIds[i].foodId)
            .then((res) => {
                if (res.status === 200) {
                    const food = res.data;
                    foods.push({...food, grams: foodsIds[i].grams});
                }   
            })
            .catch((error) => {
                console.log(error)
            })
        datt.push(promise);
    }

    let nutritionalValues = {
        kcals: 0,
        proteins: 0,
        carbohydrates: 0,
        fats: 0,
        fiber: 0
    }
    
    await Promise.all(datt)
        .then(() => {
            nutritionalValues = foods.reduce((values, food) => {
                const gr = food?.grams;
                return {
                    kcals: parseFloat(values.kcals + valueCalc(food?.kcals, gr)),
                    proteins: parseFloat(values.proteins + valueCalc(food?.proteins, gr)),
                    carbohydrates: parseFloat(values.carbohydrates + valueCalc(food?.carbohydrates, gr)),
                    fats: parseFloat(values.fats + valueCalc(food?.fats, gr)),
                    fiber: parseFloat(values.fiber + valueCalc(food?.fiber, gr))
                    
                };
            }, {kcals: 0, proteins: 0, carbohydrates: 0, fats: 0, fiber: 0})
        })

    return nutritionalValues;
}

export function activityCalcs(activity) {
    const kcals = parseFloat(activity?.activity["kcal".concat(activity?.lvl)]);

    return {
        kcals: kcals * parseFloat(activity?.time),
        carbohydrates: 0,
        fiber: 0,
        fats: 0,
        proteins: 0
    };
}

export async function blockCalc(block) {
    let nutritionalValues = {kcals: 0, carbohydrates: 0, fiber: 0, fats: 0, proteins: 0};
    
    if (block?.isIntake) {
        if (block?.intake?.recipes?.length > 0) {
            const recipeKcals = await Promise.all(
                block.intake.recipes.map(async (recipe) => {
                try {
                    const res = await recipeCalcs(recipe.recipe);
                    return res;
                } catch (error) {
                    console.log(error);
                    return 0;
                }
                })
            );
            nutritionalValues = recipeKcals.reduce((total, nt) => {
                return sumValues(total, nt)}, {
                kcals: nutritionalValues.kcals, 
                carbohydrates: nutritionalValues.carbohydrates, 
                fiber: nutritionalValues.fiber, 
                fats: nutritionalValues.fats, 
                proteins: nutritionalValues.proteins
                
            });
        }
        if (block?.intake?.foods?.length > 0) nutritionalValues = block.intake?.foods?.reduce((total, food) => {
            const nt = foodCalcs(food);
            return sumValues(total, nt)}, {
                kcals: nutritionalValues.kcals, 
                carbohydrates: nutritionalValues.carbohydrates, 
                fiber: nutritionalValues.fiber, 
                fats: nutritionalValues.fats, 
                proteins: nutritionalValues.proteins
        });
    } else {
        if (block?.exercise?.activities?.length > 0) nutritionalValues = block?.exercise?.activities?.reduce((total, activity) => {
            const nt = activityCalcs(activity);
            return sumValues(total, nt)
            }, {
                kcals: nutritionalValues.kcals, 
                carbohydrates: nutritionalValues.carbohydrates, 
                fiber: nutritionalValues.fiber, 
                fats: nutritionalValues.fats, 
                proteins: nutritionalValues.proteins
            });        
    }

    return nutritionalValues;
}

function sumValues(total, nt) {
    return {
        kcals: total.kcals + nt.kcals, 
        carbohydrates: total.carbohydrates + nt.carbohydrates, 
        fiber: total.fiber + nt.fiber, 
        fats: total.fats + nt.fats, 
        proteins: total.proteins + nt.proteins
    }
}

export function reCalcNutritionalValues(blocks) {
    let data = {};
    
    let kcals = 0;
    let carbohydrates = 0;
    let protein = 0;
    let fats = 0;
    let fatskg = 0;
    let fatspercent = 0;

    for (let i = 0; i < blocks.length; ++i) {
        const block = blocks[i];
        if (block?.isIntake) {
            if (block?.intake?.optional) {
                let kcalsSum = block?.kcals;
                let carbohydratesSum = block?.carbohydrates;
                let proteinSum = block?.proteins;
                let fatsSum = block?.fats;
                let counter = 1;
                
                for (let j = i+1; j < blocks?.length; ++j) {
                    const actBlock = blocks[j];
                    if (actBlock?.intake?.optional) {
                        kcalsSum += actBlock.kcals;
                        carbohydratesSum += actBlock.carbohydrates;
                        proteinSum += actBlock.proteins;
                        fatsSum += actBlock.fats;
                        counter++;
                    } else break;
                }

                kcals += kcalsSum/counter;
                carbohydrates += carbohydratesSum/counter;
                protein += proteinSum/counter;
                fats += fatsSum/counter;
                i += counter-1;
            } else {
                kcals += block?.kcals;
                carbohydrates += block?.carbohydrates;
                protein += block?.proteins;
                fats += block?.fats;
            }
        } else {
            kcals = kcals - block?.kcals;
        }
    }

    data.kcals = kcals;
    data.carbohydrates = carbohydrates;
    data.protein = protein;
    data.fats = fats;
    data.fatskg = fatskg;
    data.fatspercent = fatspercent;
    return data;
}

export async function getDataBlocks(blocks) {

    function sortByNumber(a, b) {
        if (a.number < b.number) return -1;
        if (a.number > b.number) return 1;
        return 0;
    }

    let data = [];

    let kcals = 0;
    let proteins = 0;
    let carbohydrates = 0;
    let fats = 0;
    let fiber = 0;

    for (let i = 0; i < blocks.length; ++i) {
        let elem = {};
        const block = blocks[i];
        const calcs = await blockCalc(block);

        elem._id = block._id;
        elem.number = block.number;
        
        elem.kcals = calcs?.kcals || 0;
        elem.proteins = calcs?.proteins || 0;
        elem.carbohydrates = calcs?.carbohydrates || 0;
        elem.fats = calcs?.fats || 0;
        elem.fiber = calcs?.fiber || 0;

        kcals += calcs?.kcals || 0;
        proteins += calcs?.proteins || 0;
        carbohydrates += calcs?.carbohydrates || 0;
        fats += calcs?.fats || 0;
        fiber += calcs?.fiber || 0;

        data.push(elem);
    }

    data.sort(sortByNumber);
    return {
        data: data,
        kcals: kcals,
        proteins: proteins,
        carbohydrates: carbohydrates,
        fats: fats,
        fiber: fiber
    };
}