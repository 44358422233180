import {
	Button,
	TextField,
	Tooltip,
	createMuiTheme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { alertError } from "../../../../utils/logger";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import { useSkeleton } from "../../../hooks/useSkeleton";
import { Col, Row } from "react-bootstrap";
import { getTramsCompetitionUser, getTramsXUser, postTramXUser, updateTramXUser } from "../../../../api/tramXuser";
import Table, { buttonsStyle } from "../../../components/tables/table";
import { ArrowDownward, ArrowUpward, Delete, Edit, LocalDining } from "@material-ui/icons";
import EditPatientTramIntakeDialog from "../../../components/dialogs/userCompetitions/EditPatientTramIntakeDialog";
import MyAutocomplete from "../../../components/MyAutocomplete";
import { getStructureById, getStructures } from "../../../../api/structure";
import EditStructureBlockDialog from "../../../components/dialogs/structures/EditStructureBlockDialog";

// Create theme for delete button (red)
const theme = createMuiTheme({
	palette: {
		secondary: {
			main: "#F64E60",
		},
	},
});

function getEmptyCompetition() {
	return {
		competitionId: null,
        competitionName: null,
	};
}

export default function EditPatientCompetition(props) {
	const { userId, dataComp, competitions, onSave, userCompetitions, setUserCompetitions, changes, setChanges, tramsXuser, setTramsXuser } = props;
	const history = useHistory();

	const [competition, setCompetition] = useState(getEmptyCompetition());
    const [competitionOptions, setCompetitionOptions] = useState([]);
    const [competitionIndex, setCompetitionIndex] = useState(-1);

    const [openEditTramUserDialog, setOpenEditTramUserDialog] = useState(false);
    const [selectedTram, setSelectedTram] = useState(null);

    const [structures, setStructures] = useState([]);
    const [structuresOptions, setStructuresOptions] = useState([]);
    const [structure, setStructure] = useState(null);
    const [openEditBlockDialog, setOpenEditBlockDialog] = useState(false);
	const [selectedBlock, setSelectedBlock] = useState(null);
	const [isMeal, setIsMeal] = useState(false);
    const [blocks, setBlocks] = useState([]);

    const [openConfirmDialog, setOpenConfirmDialog] = useState(-1);
    const [selectedStructure, setSelectedStructure] = useState(null);
    const [structureHasChanged, setStructureHasChanged] = useState(false);

	const [refresh, setRefresh] = useState(false);
	
	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

    useEffect(() => {
		if (!dataComp) {setCompetition(getEmptyCompetition());disableLoadingData();}
		else {
            setCompetition(dataComp)
            setCompetitionIndex(userCompetitions?.competitions?.findIndex(
                (x) => x.competitionId === dataComp.competitionId));

            if (userId && dataComp?.competitionId)
                actualizeTramsData(dataComp);

            setStructure(dataComp?.pre24h);
            setBlocks(dataComp?.pre24h?.blocks);

            disableLoadingData();
        }
        
	}, [dataComp]);

	useEffect(() => {
		setRefresh(false);
	}, [refresh])

    useEffect(() => {
        if (structures?.length === 0)
            getStructures()
                .then((res) => {
                    if (res.status === 200) {
                        setStructures(res.data);
                        actualizeStructuresOptions(res.data);
                    }
                })
                .catch((error) => {
                    alertError({
                        error: error,
                        customMessage: "Could not get structures.",
                    });
                });
    }, [structures])

    useEffect(() => {
        if (competitions?.length !== 0) actualizeCompetitionOptions(competitions)
    }, [competitions])

    useEffect(() => {
        if (structure) {
            const newComp = {
                ...competition,
                pre24h: structure
            };
            setCompetition(newComp)
            if (competitionIndex !== -1) {
                let newUserCompetitions = userCompetitions;
                newUserCompetitions.competitions[competitionIndex] = newComp;
                setUserCompetitions(newUserCompetitions)
            } else {
                let newUserCompetitions = userCompetitions;
                newUserCompetitions.competitions.push(newComp);
                setUserCompetitions(newUserCompetitions)
            }
            
            setStructureHasChanged(true);
            setChanges(changes+1);
        }
    }, [structure])

    function actualizeTramsData(competition) {
        /* getTramsCompetitionUser(competition?.competitionId, userId)
            .then((res) => {
                if (res.status === 200) {
                    setTramsXuser(res.data);
                    disableLoadingData();
                }
            })
            .catch((error) => {
                alertError({
                    error: error,
                    customMessage: "Could not get tram x users.",
                });
            }); */
    }

	const tramsTable = [
        {
            dataField: "name",
            text: "title"
        },
        {
            dataField: "foods",
            text: "quantity",
            align: 'center',
            headerAlign: "center",
            formatter: quantityFormatter
        },
        {
            dataField: "foods",
            text: "food",
            align: 'center',
            headerAlign: "center",
            formatter: foodsFormatter
        },
        {
            dataField: "foods",
            text: "observations",
            align: 'center',
            headerAlign: "center",
            formatter: observationsFormatter
        },
        {
            dataField: "number",
            text: "",
            formatter: tramsButtonFormatter,
            align: "right",
        }
    ]

    function quantityFormatter(cell) {
        let text = "";

        if (cell?.length > 0) {
            text = cell[0].quantity;
            for (let i = 1; i < cell?.length; ++i) {
                const actF = cell[i];
                text += "<br>" + actF.quantity;
            }
        }
    
        return <span dangerouslySetInnerHTML={{ __html: text }} />;
    }

    function foodsFormatter(cell) {
        let text = "no intake";

        if (cell?.length > 0) {
            text = cell[0].name;
            for (let i = 1; i < cell?.length; ++i) {
                const actF = cell[i];
                text += "<br>" + actF.name;
            }
        }
    
        return <span dangerouslySetInnerHTML={{ __html: text }} />;
    }

    function observationsFormatter(cell) {
        let text = "";

        if (cell?.length > 0) {
            text = cell[0].observations;
            for (let i = 1; i < cell?.length; ++i) {
                const actF = cell[i];
                text += "<br>" + actF.observations;
            }
        }
    
        return <span dangerouslySetInnerHTML={{ __html: text }} />;
    }

	function tramsButtonFormatter(cell) {
        const tram = tramsXuser?.find((x => parseFloat(x.numTram) === parseFloat(cell)));

        return (
			<>
				{cell != 0 && (
					<> 
						<Tooltip title="Edit intake">
							<Button
								style={buttonsStyle}
								size="small"
								onClick={() => {
									setOpenEditTramUserDialog(true);
									setSelectedTram(tram);
								}}
							>
								<LocalDining />
							</Button>
						</Tooltip>
					</>
				) 
				}
				
			</>
		)
	}

	function actualizeCompetitionOptions(competitions) {
        let data = [];

        for (let i = 0; i < competitions?.length; ++i) {
            let elem = {};
            const act = competitions[i];

            elem.competitionId = act._id;
            elem.competitionName = act.fullName.es;

            data.push(elem);
        }

        setCompetitionOptions(data);
    }

	function actualizeStructuresOptions(structures) {
		let data = [];

		for (let i = 0; i < structures?.length; ++i) {
			let elem = {}
			const act = structures[i];

			elem._id = act._id;
			elem.name = act.fullName.es;

			data.push(elem);
		}

		setStructuresOptions(data);
	}

    function getTramsTableData(tramsXuser) {
        function sortByNumber(a, b) {
            if (a.number > b.number) return 1;
            if (a.number < b.number) return -1;
            return 0;
        }

        let data = [];

        const comp = competitions.find((x) => x._id === competition.competitionId)
        const trams = comp?.trams;

        for (let i = 0; i < trams?.length; ++i) {
            let elem = {};
            const act = trams[i];
            const definedTram = tramsXuser.find((x) => parseInt(x.numTram) === act.number)
            
            elem.name = act.name;
            elem.number = act.number;
            elem.foods = definedTram?.foods || [];

            data.push(elem);
        }

        data.sort(sortByNumber)

        return data;
    }

	const handleChange = (element, lang) => (event) => {
		if (event.target.value === " ") return;
		if (lang) {
			//if (!food[element]) food[element] = {};
			//let newText = food[element];
			//newText[lang] = event.target.value;
			//setFood({ ...food, [element]: newText });
		} else {
			
		}
	};

    const blockColumns = [
		{
			dataField: "number",
			text: "title",
			formatter: titleFormatter
		},
		{
			dataField: "number",
			text: "type",
			formatter: typeFormatter
		},
		{
			dataField: "number",
			text: "",
			formatter: blocksButtonFormatter,
			align: 'right',
			headerStyle: { width: '200px'}
		}
	];

    function titleFormatter(cell) {
		const block = structure?.blocks.find((x) => cell === x.number);
		if (block?.intake?.optional) return block?.name + " (Optional)";
		else return block?.name;
	}

    function typeFormatter(cell) {
		const block = blocks.find((x) => cell === x.number);
		return (block?.isIntake? block?.intake.isSnack? "Snack" : "Main" : "Exercise");
	}

    function blocksButtonFormatter(cell) {
		const index = blocks.findIndex((x) => x.number === cell);
		const block = blocks[index];

		return (
			<>
				<Tooltip title="Edit">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
							setOpenEditBlockDialog(true);
							setSelectedBlock(block);
							setIsMeal(block.isIntake);
						}}
					>
						<Edit />
					</Button>
				</Tooltip>
				<Tooltip title="Move up">
					<Button
						size="small"
						style={buttonsStyle}
						disabled={index === 0}
						onClick={() => blockMove(block, index, index-1)}
					>
						<ArrowUpward />
					</Button>
				</Tooltip>
				<Tooltip title="Move down">
					<Button
						size="small"
						disabled={
							index >= structure.blocks?.length - 1
						}
						style={buttonsStyle}
						onClick={() => blockMove(block, index, index+1)}
					>
						<ArrowDownward />
					</Button>
				</Tooltip>
				<Tooltip title="Delete">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
							let newBlocks = blocks;
							newBlocks.splice(index, 1);

							const data = reCalcNutritionalValues(newBlocks);

							setStructure({
								...structure,
								kcal: data.kcals,
								carbohydrates: data.carbohydrates,
								protein: data.protein,
								fats: data.fats,
								fatskg: data.fatskg,
								fatspercent: data.fatspercent,
								blocks: blocks,
							});
							setBlocks(newBlocks);
							setRefresh(true);
						}}
					>
						<Delete />
					</Button>
				</Tooltip>
			</>
		)
	}

    function blockMove(block, index, newIndex) {
		let blocks = [
			...structure.blocks
		]
		const blockUp = blocks.find(
			(x) => x.number === newIndex
		)
		
		blocks[newIndex] = {
			...block,
			number: newIndex
		};
		blocks[index] = {
			...blockUp,
			number: index
		}

		const data = reCalcNutritionalValues(blocks);

		setStructure({
			...structure,
			kcal: data.kcals,
			carbohydrates: data.carbohydrates,
			protein: data.protein,
			fats: data.fats,
			fatskg: data.fatskg,
			fatspercent: data.fatspercent,
			blocks: blocks,
		});
		setBlocks(blocks);
		setRefresh(true);
	}

    function reCalcNutritionalValues(blocks) {
		let data = {};
		
		let kcals = 0;
		let carbohydrates = 0;
		let protein = 0;
		let fats = 0;
		let fatskg = 0;
		let fatspercent = 0;

		for (let i = 0; i < blocks.length; ++i) {
			const block = blocks[i];
			if (block?.isIntake) {
				if (block?.intake?.optional) {
					let kcalsSum = block?.kcals;
					let carbohydratesSum = block?.carbohydrates;
					let proteinSum = block?.proteins;
					let fatsSum = block?.fats;
					let counter = 1;
					
					for (let j = i+1; j < blocks?.length; ++j) {
						const actBlock = blocks[j];
						if (actBlock?.intake?.optional) {
							kcalsSum += actBlock.kcals;
							carbohydratesSum += actBlock.carbohydrates;
							proteinSum += actBlock.proteins;
							fatsSum += actBlock.fats;
							counter++;
						} else break;
					}

					kcals += kcalsSum/counter;
					carbohydrates += carbohydratesSum/counter;
					protein += proteinSum/counter;
					fats += fatsSum/counter;
					i += counter-1;
				} else {
					kcals += block?.kcals;
					carbohydrates += block?.carbohydrates;
					protein += block?.proteins;
					fats += block?.fats;
				}
			} else {
				kcals = kcals - block?.kcals;
			}
		}

		data.kcals = kcals;
		data.carbohydrates = carbohydrates;
		data.protein = protein;
		data.fats = fats;
		data.fatskg = fatskg;
		data.fatspercent = fatspercent;
		return data;
	}

    function getDataBlocks(blocks) {

		function sortByNumber(a, b) {
			if (a.number < b.number) return -1;
			if (a.number > b.number) return 1;
			return 0;
		}

		let data = [];

		for (let i = 0; i < blocks.length; ++i) {
			let elem = {};
			const block = blocks[i];

			elem._id = block._id;
			elem.number = block.number;

			data.push(elem);
		}

		data.sort(sortByNumber);

		return data;
	}

    function changeStructure(structureId) {
        getStructureById(structureId)
			.then((res) => {
				if (res.status === 200) {
					let structureData = res.data;

					if (structureData?.block?.length > 0) {
						for (
							let i = 0;
							i < structureData?.block?.length;
							++i
						) {
							structureData.block[i].id = i;
						}
					}

					setStructure(res.data);
					setBlocks(res.data.blocks)
					disableLoadingData();
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get structure.",
				});
				history.push("/structures");
			});
    }

    if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<>
				<Card>
                        <ConfirmDialog
                        title={
                        "Are you sure you want to change the structure? You will lose all your changes"
                        }
                        open={openConfirmDialog === 1}
                        setOpen={setOpenConfirmDialog}
                        onConfirm={() => {
                            changeStructure(selectedStructure._id);
                            setRefresh(true);
                            setOpenConfirmDialog(-1);
                        }}
                    />
                    <MyAutocomplete
                        options={ competitionOptions }
                        getOptionLabel={(option) =>
                            option.competitionName
                        }
                        value={ competitionOptions.find(option => option.competitionId === competition?.competitionId) || null }
                        onChange={(event, selected) => {
                            if (userId) actualizeTramsData(selected);
                            setCompetition({
                                ...competition,
                                competitionId: selected.competitionId,
                                competitionName: selected.competitionName
                            });
                            setChanges(changes+1);
                            setRefresh(true)
                        }}
                        placeholder="Select competition"
                        label={"Type of competition"}
                    />

                    <br />
                    <h3> Pre 24h</h3>
                    
                    <MyAutocomplete
                        options={ structuresOptions }
                        getOptionLabel={(option) =>
                            option.name
                        }
                        value={ structuresOptions.find(option => option._id === structure?._id) || null }
                        onChange={(event, selected) => {
                            if (structureHasChanged) {
                                setOpenConfirmDialog(1)
                                setSelectedStructure(selected);
                            } else {
                                changeStructure(selected._id);
                                setRefresh(true);
                            }
                        }}
                        placeholder="Select structure"
                        label={"Type of structure"}
                    />

                    <h3>Structure</h3>

                    <Row>
                        <Col>
                            <TextField
                                id={`kcal`}
                                label="KCal"
                                value={structure?.kcal?.toFixed(2)}
                                InputLabelProps={{
                                shrink: true
                                }}
                                margin="normal"
                                variant="standard"
                                required
                                type="number"
                                className='readonly'
                            />
                        </Col>
                        <Col>
                            <TextField
                                id={`carbohydrates`}
                                label="Carbohydrates"
                                value={structure?.carbohydrates?.toFixed(2)}
                                InputLabelProps={{
                                shrink: true
                                }}
                                margin="normal"
                                variant="standard"
                                required
                                type="number"
                                className='readonly'
                            />
                        </Col>
                        <Col>
                            <TextField
                                id={`protein`}
                                label="Protein"
                                value={structure?.protein?.toFixed(2)}
                                InputLabelProps={{
                                shrink: true
                                }}
                                margin="normal"
                                variant="standard"
                                required
                                type="number"
                                className='readonly'
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <TextField
                                id={`fats`}
                                label="Fats"
                                value={structure?.fats?.toFixed(2)}
                                InputLabelProps={{
                                shrink: true
                                }}
                                margin="normal"
                                variant="standard"
                                required
                                type="number"
                                className='readonly'
                            />
                        </Col>
                        <Col>
                            <TextField
                                id={`fatskg`}
                                label="Fats Kg"
                                value={structure?.fatskg?.toFixed(2)}
                                InputLabelProps={{
                                shrink: true
                                }}
                                margin="normal"
                                variant="standard"
                                required
                                type="number"
                                className='readonly'
                            />
                        </Col>
                        <Col>
                            <TextField
                                id={`fatspercent`}
                                label="Fats percent"
                                value={structure?.fatspercent?.toFixed(2)}
                                InputLabelProps={{
                                shrink: true
                                }}
                                margin="normal"
                                variant="standard"
                                required
                                type="number"
                                className='readonly'
                            />
                        </Col>
                    </Row>
                    
                    <CardHeader title="Block">
						<CardHeaderToolbar>
							<button
								type="button"
								className="btn btn-primary"
								onClick={() => {
									setOpenEditBlockDialog(true);
									setSelectedBlock(null);
									setIsMeal(false);
								}}
							>
								Add exercise
							</button>
							<button
								type="button"
								className="btn btn-primary"
								style={{marginLeft: 10}}
								onClick={() => {
									setOpenEditBlockDialog(true);
									setSelectedBlock(null);
									setIsMeal(true)
								}}
							>
								Add meal
							</button>
						</CardHeaderToolbar>
					</CardHeader>
					<CardBody>
						{!refresh &&
							blocks?.length > 0 && (
								<Table
									columns={blockColumns}
									data={getDataBlocks(blocks)}
								/>
							)}
					</CardBody>
					<EditStructureBlockDialog
						open={openEditBlockDialog}
						setOpen={setOpenEditBlockDialog}
						data={selectedBlock}
						meal={isMeal}

						onSave={(block) => {
							let newBlocks = (blocks?.length > 0)? [...blocks] : [];

							const index = newBlocks.findIndex(
								(x) => x.number === block.number
							);
							
							if (index !== -1) {
								newBlocks[index] = {
									...block,
								};
							} else {
								newBlocks.push({
									...block,
									number: newBlocks.length
								});
							}

							const data = reCalcNutritionalValues(newBlocks);

							setStructure({
								...structure,
								kcal: data.kcals,
								carbohydrates: data.carbohydrates,
								protein: data.protein,
								fats: data.fats,
								fatskg: data.fatskg,
								fatspercent: data.fatspercent,
								blocks: newBlocks,
							});
							setBlocks(newBlocks);
							setRefresh(true);
						}}
					/>

                    <CardHeader title="Trams" />
                    <CardBody>
                        {!refresh && (
                            <Table 
                                columns={tramsTable}
                                data={getTramsTableData(tramsXuser)}
                            />
                        )}
                    </CardBody>
                    <EditPatientTramIntakeDialog 
                        open={openEditTramUserDialog}
                        setOpen={setOpenEditTramUserDialog}
						data={selectedTram}

						onSave={(intakeTram) => {
                            let newTrams = tramsXuser;

                            const index = tramsXuser?.findIndex((x) => x.numTram === intakeTram.numTram);
                            if (index === -1) newTrams.push(intakeTram);
                            else newTrams[index] = intakeTram;

                            setTramsXuser(newTrams);
                            setRefresh(true);
						}}
                    />
                    
                   
                </Card>
			</>
		);
}
