import React, { useEffect, useState } from "react";
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import FullCalendarView from "../../../components/calendar/FullCalendarView";
import EditVisitDialog from "../../../components/dialogs/EditVisitDialog";
import { getVisitTypes } from "../../../../api/visitType";
import { alertError, alertSuccess } from "../../../../utils/logger";
import { getPatients } from "../../../../api/user";
import { deleteVisit, postVisit, updateVisit } from "../../../../api/visit";

export default function CalendarPage() {
	const [date, setDate] = useState(null);
	const [openEditVisitDialog, setOpenEditVisitDialog] = useState(false);
	const [visitTypes, setVisitTypes] = useState([]);
	const [visits, setVisits] = useState([]);

	const [users, setUsers] = useState([]);
	const [selectedVisit, setSelectedVisit] = useState(null);

	const [refresh, setRefresh] = useState(false);

	useEffect(() => {
		setRefresh(false);
	},[refresh])

	useEffect(() => {
        if (visitTypes?.length === 0)
			getVisitTypes()
				.then((res) => {
				if (res.status === 200) {
					setVisitTypes(res.data);
				}
				})
				.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get visit types.",
				});
				});
		
		if (users?.length === 0)
			getPatients()
				.then((res) => {
					if (res.status === 200) {
						setUsers(res.data);
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not get patients.",
					});
				});
	}, []);

	function saveVisit(visit) {
		if (visit?._id)
			updateVisit(visit._id, visit)
				.then((res) => {
					alertSuccess({
						title: 'Saved!',
						customMessage: "Visit updated succesfully"
					})
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not update visit"
					})
				})
		else {
			postVisit(visit)
				.then((res) => {
					alertSuccess({
						title: 'Saved!',
						customMessage: "Visit updated succesfully"
					})
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not save visit"
					})
				})
		}
	}

	async function deleteVisitFromBD(visit) {
        const res = await deleteVisit(visit?._id)
            .catch((error) => {
                alertError({
                    error: error,
                    customMessage: "Could not erase visit"
                });
                return false;
            })   
        
        alertSuccess({
            title: "Deleted!",
            customMessage: "Visit deleted successfully"
        })
        return true;
    }

	return (
		<>
			<Card>
				<CardHeader title="Calendar">
				</CardHeader>
				<CardBody>
					{!refresh && (<FullCalendarView 
					setDate={setDate}
					setSelectedVisit={setSelectedVisit}
					visits={visits}
					setVisits={setVisits}
					setOpenEditVisitDialog={setOpenEditVisitDialog}
					/>)}
					{openEditVisitDialog && (
						<EditVisitDialog 
						data={selectedVisit}
						open={openEditVisitDialog}
						setOpen={setOpenEditVisitDialog}
						visitTypes={visitTypes}
						userId={selectedVisit?.user? selectedVisit?.user : null}
						users={users}
						date={date}
						predefPatient={selectedVisit?.user? true : false}
						deleteOption={true}
						onSave={(visit) => {
							let newVisits = [...visits] || [];
							const index = newVisits?.findIndex((x) => newVisits?._id === visit?._id);

							if (index === -1) newVisits.push(visit);
							else newVisits[index] = visit;

							setVisits(newVisits);
							saveVisit(visit);
							setRefresh(true);
						}}
						onDelete={(visit) => {
							let newVisits = [...visits] || [];
							const index = newVisits?.findIndex((x) => newVisits?._id === visit?._id);
							newVisits.splice(index, 1);
							setVisits(newVisits);
							deleteVisitFromBD(visit);
							setRefresh(true);
						}}
						/>
					)}
				</CardBody>
			</Card>
		</>
	);
}
