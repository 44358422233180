import {
	Button,
	Card,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	TextField,
	Tooltip,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { alertError } from "../../../../utils/logger";
import MultilanguageTabBlock from "../../MultilanguageTabBlock";
import Editor from "../../editor/Editor";
import Table, { buttonsStyle } from "../../tables/table";
import { ArrowDownward, ArrowUpward, Delete, Edit, Height } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { Col, Row } from "react-bootstrap";
import { getFoods } from "../../../../api/food";
import { getFoodPlates } from "../../../../api/foodPlate";
import { getActivities } from "../../../../api/activity";
import { CardBody } from "../../../../_metronic/_partials/controls";
import _ from 'lodash';
import EditBlockFoodDialog from "./EditBlockFoodDialog";
import EditBlockActivityDialog from "./EditBlockActivityDialog";
import { blockCalc } from "../../../../utils/structuresCalcs";
import { formatFloat } from "../../../../utils/helpers";
import { useSkeleton } from "../../../hooks/useSkeleton";

function getEmptyNutritionalValues() {
	return {
		kcals: 0,
		proteins: 0,
		carbohydrates: 0,
		fats: 0,
		fiber: 0,
	}
}

const EditStructureBlockDialog = (props) => {
	const { title, data, open, setOpen, onSave, readOnly, meal } = props;

	const [block, setBlock] = useState(getEmptyStructureBlock());
	const [infoElements, setInfoElements] = useState([]);

	const [foods, setFoods] = useState([]);
	const [foodPlates, setFoodPlates] = useState([]);
	const [activities, setActivities] = useState([]);

	const [openEditMealDialog, setOpenEditMealDialog] = useState(false);
	const [selectedMeal, setSelectedMeal] = useState(null);
	const [isFood, setIsFood] = useState(false);

	const [openEditActivityDialog, setOpenEditActivityDialog] = useState(false);
	const [selectedActivity, setSelectedActivity] = useState(null);

	const [editorBody, setEditorBody] = useState("");
	const [refresh, setRefresh] = useState(false);

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	function getEmptyStructureBlock() {
		return {
			name: null,
			description: null,
			isIntake: meal,
			number: -1,
			kcals: null,
			proteins: null,
			carbohydrates: null,
			fats: null,
			fiber: null
		};
	}

	const elementsTable = [
		{
			dataField: "type",
			text: "type"
		},
		{
			dataField: "fullName",
			text: "Name",
			headerAlign: 'center',
			align: 'center'
		},
		{
			dataField: "kcals",
			text: "kcals",
			headerAlign: 'center',
			align: 'center',
			formatter: formatFloat
		},
		{
			dataField: "proteins",
			text: "proteins",
			headerAlign: 'center',
			align: 'center',
			formatter: formatFloat
		},
		{
			dataField: "carbohydrates",
			text: "carbohydrates",
			headerAlign: 'center',
			align: 'center',
			formatter: formatFloat
		},
		{
			dataField: "fats",
			text: "fats",
			headerAlign: 'center',
			align: 'center',
			formatter: formatFloat
		},
		{
			dataField: "fiber",
			text: "fiber",
			headerAlign: 'center',
			align: 'center',
			formatter: formatFloat,
		},
		{
			dataField: "number",
			text: "",
			formatter: elementsButtonFormatter,
			align: 'right',
			headerAlign: 'center',
			headerStyle: { width: '200px'}
		}
	];

	const activitiesTable = [
		{
			dataField: "fullName",
			text: "name"
		},
		{
			dataField: "lvl",
			text: "level",
			headerAlign: 'center',
			align: 'center'
		},
		{
			dataField: "time",
			text: "time (in mins)",
			headerAlign: 'center',
			align: 'center'
		},
		{
			dataField: "kcals",
			text: "kcals",
			headerAlign: 'center',
			align: 'center',
			formatter: formatFloat
		},	
		{
			dataField: "number",
			text: "",
			formatter: elementsButtonFormatter,
			align: 'right',
			headerStyle: { width: '200px'}
		},
	];

	useEffect(() => {
		setRefresh(false);
	}, [refresh])

	useEffect(() => {
		const fetchData = async() => {
			try {
				if (!open || !data) setBlock(await recalcBlockValues(getEmptyStructureBlock()));
				else {
					const dat = _.cloneDeep(data);
					if (meal && dat?.intake) {
						setBlock(await recalcBlockValues({
							...dat,
							intake: {
								...dat.intake,
								recipes: dat.intake.recipes,
								foods: dat.intake.foods,
								isSnack: (dat?.intake?.isSnack === true)? '1' : '0'
							}
						}))
					}
					else setBlock(await recalcBlockValues(dat));
				}
				setInfoElements(infoElementsData(data));
			}
			
			catch (e) {
				console.log("ERROR: ", e);
			}
		}

		fetchData();
	}, [data, open]);

	useEffect(() => {
		if (foods?.length === 0)
			getFoods()
				.then((res) => {
					if (res.status === 200) {
						const foods = res.data;
						setFoods(foods);
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not get foods.",
					});
				});
		if (foodPlates?.length === 0)
			getFoodPlates()
				.then((res) => {
					if (res.status === 200) {
						const foodPlates = res.data;
						setFoodPlates(foodPlates);
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not get food plates"
					});
				});
		
		if (activities?.length === 0)
			getActivities()
				.then((res) => {
					if (res.status === 200) {
						const activities = res.data;
						setActivities(activities);
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not get activities"
					});
				});
	}, [])

	useEffect(() => {
		if (block?.description !== null) setEditorBody(block?.description || "");
	}, [block?.description])

	useEffect(() => {
		disableLoadingData();
	}, [editorBody])

	async function recalcBlockValues(block) {
		const calcs = await blockCalc(block);

		const newBlock = {
			...block,
			kcals: calcs?.kcals,
			carbohydrates: calcs?.carbohydrates,
			proteins: calcs?.proteins,
			fats: calcs?.fats,
			fiber: calcs?.fiber
		}

		return newBlock;
	}

	function infoElementsData(block) {
		let data = [];
		
		if (block?.isIntake) {
			for (let i = 0; i < block?.intake?.recipes?.length; ++i) {
				let elem = {};
				const info = block.intake.recipes[i];
				
				elem.number = info?.number;
				elem.type = "Recipe";
				elem.fullName = info?.recipe?.description?.es || 
								info?.recipe?.fullName;
				elem._id = info?.recipe._id;

				data.push(elem);
			}

			for (let i = 0; i < block?.intake?.foods?.length; ++i) {
				let elem = {};
				const info = block.intake.foods[i];

				elem.number = info?.number;
				elem.type = "Food";
				elem.fullName = info?.food?.fullName?.es ||
								info?.food?.fullName;
				elem._id = info?.food._id;
				elem.grams = info?.grams;

				elem.portions = info?.food?.portions || [];
				elem.selectedPortion = (info?.selectedPortion >= 0)? info?.selectedPortion : -1;
				elem.portionQuant = info?.portionQuant || 0;

				data.push(elem);
			}
		} else {
			for (let i = 0; i < block?.exercise?.activities?.length; ++i) {
				let elem = {};
				const info = block.exercise.activities[i];

				elem.number = info?.number;
				elem.type = "Activity";
				elem.fullName = info?.activity.fullName.es;
				elem.time = info?.time;
				elem.lvl = info?.lvl;
				elem._id = info?.activity._id;

				data.push(elem);
			}
		}

		data.sort(sortByNumber)

		return data;
	}

	async function elementMove(index, newIndex) {
		
		function actualizeInfo(elem, indexx, newIndexx, newBlock) {
			if (elem.type === 'Recipe') {
				const index = block.intake.recipes.findIndex(
					(x) => x.number === indexx);
	
				newBlock.intake.recipes[index].number = newIndexx;
			} else if (elem.type === 'Food') {
				const index = block.intake.foods.findIndex(
					(x) => x.number === indexx);
				
				newBlock.intake.foods[index].number = newIndexx;
			} else { //type === "Activity"
				const index = block.exercise.activities.findIndex(
					(x) => x.number === indexx);
				
				newBlock.exercise.activities[index].number = newIndexx;
			}
		}
		
		let elems = infoElements;
		let elem1 = elems[index];
		let elem2 = elems[newIndex];
		elem1.number = newIndex;
		elem2.number = index;
		elems[newIndex] = elem1;
		elems[index] = elem2;

		let newBlock = _.cloneDeep(block);
		actualizeInfo(elem1, index, newIndex, newBlock);
		actualizeInfo(elem2, newIndex, index, newBlock);
		setBlock({...newBlock});

		setInfoElements(elems);
		// setRefresh(true);
	}

	async function deleteElem(cell, row) {
		let elems = _.cloneDeep(infoElements);
		const type = elems[cell].type;
		for (let i = cell+1; i < elems?.length; ++i) elems[i].number = i - 1;
		elems.splice(cell, 1);
		setInfoElements(elems);

		let newBlock = block;
		if (meal) {
			if (type === 'Recipe') {
				const index = newBlock.intake.recipes.findIndex(
					(x) => x.number === row?.number
				)

				newBlock.intake.recipes.splice(index, 1);
			} else {
				const index = newBlock.intake.foods.findIndex(
					(x) => x.number === row?.number
				)

				newBlock.intake.foods.splice(index, 1);
			}

			for (let i = 0; i < newBlock.intake.recipes?.length; ++i) {
				const number = newBlock.intake.recipes[i].number;
				if (number > row?.number)
					newBlock.intake.recipes[i].number = number - 1;
			}
			for (let i = 0; i < newBlock.intake.foods?.length; ++i) {
				const number = newBlock.intake.foods[i].number;
				if (number > row?.number)
					newBlock.intake.foods[i].number = number - 1;
			}

			setBlock(await recalcBlockValues({...newBlock}));
		} else {
			const index = newBlock.exercise.activities.findIndex(
				(x) => x.number === row?.number
			);
			
			let actv = newBlock.exercise.activities;
			actv.splice(index, 1);
			newBlock.exercise.activities = actv;

			for (let i = 0; i < newBlock.exercise.activities?.length; ++i) {
				const number = newBlock.exercise.activities[i].number;
				if (number > row?.number)
					newBlock.exercise.activities[i].number = number - 1;
			}

			setBlock(await recalcBlockValues({...newBlock}));
		}

		setRefresh(true);
	}

	function elementsButtonFormatter(cell, row) {
		return (
			<>	
			<div >
				
			<Tooltip title="Edit" style={{width: 500}}>
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
							if (meal) {
								setOpenEditMealDialog(true);
								setSelectedMeal(infoElements[cell]);
								setIsFood(
									(infoElements[cell].type === 'Food')?
									true : false);
							} else {
								setOpenEditActivityDialog(true);
								setSelectedActivity(infoElements[cell]);
							}
						}}
					>
						<Edit />
					</Button>
				</Tooltip>
				<Tooltip title="Move up">
					<Button
						size="small"
						style={buttonsStyle}
						disabled={cell === 0}
						onClick={() => elementMove(cell, cell-1)}
					>
						<ArrowUpward />
					</Button>
				</Tooltip>
				<Tooltip title="Move down">
					<Button
						size="small"
						disabled={
							cell >= infoElements?.length-1
						}
						style={buttonsStyle}
						onClick={() => elementMove(cell, cell+1)}
					>
						<ArrowDownward />
					</Button>
				</Tooltip>
				<Tooltip title="Delete">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => deleteElem(cell, row)}
					>
						<Delete />
					</Button>
				</Tooltip>
			</div>
			
			</>
		)
	}

	const handleChange = (element, lang) => (event) => {
		if (event.target.value === " ") return;
		if (lang) {
			if (!block[element]) block[element] = {};
			let newText = block[element];
			newText[lang] = event.target.value;
			setBlock({ ...block, [element]: newText });
		} else setBlock({ ...block, [element]: event.target.value });
	};

	const handleChangeEditor = (element, value, lang) => {
		if (value?.trim() !== "") {
			if (lang) {
				if (!block[element]) block[element] = {};
				let newText = block[element];
				newText[lang] = value;
				setBlock({ ...block, [element]: newText });
			} else {
				setBlock({ ...block, [element]: value });
			} 
		}
	};

	const renderMultilanguageTabContent = (lang) => {
		return (
			<>
				<br />
				<TextField
					id={`title`}
					label="Title"
					value={(block?.name) || ""}
					onChange={handleChange("name", false)}
					InputLabelProps={{
						shrink: true,
					}}
					margin="normal"
					variant="standard"
					required
					disabled={readOnly}
				/>
				<br />
				
			</>
		);
	};
	
	const mealOptions = [
		{ _id: '0', fullName: 'Main' },
		{ _id: '1', fullName: 'Snack' },
	  ];
	
	function sortByNumber(a, b) {
		if (a.number < b.number) return -1;
		if (a.number > b.number) return 1;
		return 0;
	}

	function getInfoElementsData(elems) {

		let data = [];

		for (let i = 0; i < elems?.length; ++i) {
			let elem = {};
			const info = elems[i];
			const type = info?.type;
			elem.type = type;
			elem.fullName = info?.fullName;
			elem.number = info?.number;
			
			if (type === 'Activity') {
				elem.lvl = info?.lvl;
				elem.time = info?.time;
				if (!info?.kcals){
					const ca = activities.find((x) => x._id === info?._id)
					if (ca) {
						const kcals = parseFloat(ca["kcal".concat(info?.lvl)]);
						elem.kcals = parseFloat(kcals) * parseFloat(info?.time);
					} else {
						elem.kcals = 0;
					}
					
				}  else elem.kcals = info?.kcals;
			} else if (type === 'Food') {
				const complFood = foods.find((x) => x._id === info?._id);
				const calc = parseFloat(info?.grams) / 100 || 0;
				elem.kcals = parseFloat(complFood?.kcals) * calc;
				elem.proteins = parseFloat(complFood?.proteins) * calc;
				elem.carbohydrates = parseFloat(complFood?.carbohydrates) * calc;
				elem.fats = parseFloat(complFood?.fats) * calc;
				elem.fiber = parseFloat(complFood?.fiber) * calc;
			} else {
				const complRecipe = foodPlates.find((x) => x._id === info?._id);
				elem.kcals = parseFloat(complRecipe?.totalKcals);
				elem.proteins = parseFloat(complRecipe?.totalProteins);
				elem.carbohydrates = parseFloat(complRecipe?.totalCarbohydrates);
				elem.fats = parseFloat(complRecipe?.totalFats);
				elem.fiber = parseFloat(complRecipe?.totalFiber);
			}

			data.push(elem);
		}
		
		return data;
	}

	async function saveNewFood(meal) {

		const newNumber = (block?.intake?.foods?.length || 0) + 
						(block?.intake?.recipes?.length || 0);
		
		const index = block?.intake?.foods?.findIndex(
			(x) => x.number === meal?.number);
		
		const completeFood = foods?.find((x) => meal._id === x._id);
		
		if (index !== -1 && index !== undefined) {

			const newFood = {
				food: {
					...completeFood
				},
				number: meal?.number,
				grams: meal?.grams,
				selectedPortion: (meal?.selectedPortion >= 0)? meal?.selectedPortion : -1,
				portionQuant: meal?.portionQuant || 0
			}

			let newFoods = _.cloneDeep(block?.intake?.foods);
			newFoods[index] = newFood;

			let newBlock = {
				...block,
				intake: {
					...block.intake,
					foods: newFoods
				},
			};
			
			setBlock(await recalcBlockValues(newBlock));
			let newElems = infoElements;
			newElems[meal?.number] = {
				number: meal?.number,
				type: meal?.type,
				fullName: meal?.fullName,
				_id: meal?._id,
				grams: meal?.grams,
				portions: meal?.portions || [],
				selectedPortion: (meal?.selectedPortion >= 0)? meal?.selectedPortion : -1,
				portionQuant: meal?.portionQuant || 0
			}
			setInfoElements(newElems);
		}
		else { //Nueva food

			let newFoods = _.cloneDeep(block?.intake?.foods) || [];
			newFoods.push({
				food: {
					...completeFood
				},
				number: newNumber,
				grams: meal?.grams,
				selectedPortion: (meal?.selectedPortion >= 0)? meal?.selectedPortion : -1,
				portionQuant: meal?.portionQuant || 0
			})

			let newBlock = {
				...block,
				intake: {
					...block?.intake,
					foods: newFoods
				},
			}

			setBlock(await recalcBlockValues(newBlock));

			let newElems = infoElements;
			newElems.push({
				number: newNumber,
				type: 'Food',
				fullName: meal?.fullName,
				_id: meal?._id,
				grams: meal?.grams,
				portions: meal?.portions || [],
				selectedPortion: (meal?.selectedPortion >= 0)? meal?.selectedPortion : -1,
				portionQuant: meal?.portionQuant || 0
			})
			setInfoElements(newElems);
		}

		setRefresh(true);
	}

	async function saveNewRecipe(meal) {

		const newNumber = (block?.intake?.foods?.length || 0) + 
						(block?.intake?.recipes?.length || 0);

		const index = block?.intake?.recipes?.findIndex(
			(x) => x.number === meal?.number);

		const completeRecipe = foodPlates?.find((x) => x._id === meal?._id);
		
		if (index !== -1 && index !== undefined) {

			const newRecipe = {
				recipe: {
					...completeRecipe
				},
				number: meal?.number,
			}

			let newRecipes = _.cloneDeep(block?.intake?.recipes);
			newRecipes[index] = newRecipe;

			let newBlock = {
				...block,
				intake: {
					...block.intake,
					recipes: newRecipes
				},
			};

			setBlock(await recalcBlockValues(newBlock))

			let newElems = infoElements;
			newElems[meal?.number] = {
				number: meal?.number,
				type: meal?.type,
				fullName: meal?.fullName,
				_id: meal?._id
			}
			setInfoElements(newElems);
		} else {
			let newRecipes = _.cloneDeep(block?.intake?.recipes) || [];
			newRecipes.push({
				recipe: {
					...completeRecipe
				},
				number: newNumber
			})

			let newBlock = {
				...block,
				intake: {
					...block.intake,
					recipes: newRecipes
				},
			};
			setBlock(await recalcBlockValues(newBlock));

			let newElems = infoElements;
			newElems.push({
				number: newNumber,
				type: 'Recipe',
				fullName: meal?.fullName,
				_id: meal?._id
			})
			setInfoElements(newElems);
		}

		setRefresh(true);
	}

	function saveNewActivity(activity, activityAnt) {
		const newKcal = activity?.kcals;

		const newNumber = infoElements?.length || 0;

		const index = block?.exercise?.activities?.findIndex(
			(x) => x.number === activity?.number);

		const completeAct = activities?.find((x) => x._id === activity?._id);
		
		if (index !== -1 && index !== undefined) {
			const kcalsAnt = activityAnt?.kcals;

			const newActivity = {
				activity: {
					...completeAct
				},
				lvl: activity?.lvl,
				time: activity?.time,
				kcals: activity?.kcals,
				number: activity?.number,
			}

			let newActivities = _.cloneDeep(block?.exercise?.activities);
			newActivities[index] = newActivity;

			const newBlock = {
				...block,
				exercise: {
					...block.exercise,
					activities: newActivities
				},
				kcals: block?.kcals - kcalsAnt + newKcal,
			};

			setBlock(newBlock);
			let newElems = infoElements;
			newElems[activity?.number] = {
				number: activity?.number,
				type: activity?.type,
				fullName: activity?.fullName,
				_id: activity?._id,
				lvl: activity?.lvl,
				time: activity?.time,
				kcals: activity?.kcals,
			}
			
			setInfoElements(newElems);
		} else {
			let newActivities = _.cloneDeep(block?.exercise?.activities) || [];
			newActivities.push({
				activity: {
					...completeAct
				},
				lvl: activity?.lvl,
				time: activity?.time,
				kcals: activity?.kcals,
				number: newNumber,
			})

			const newBlock = {
				...block,
				exercise: {
					...block.exercise,
					activities: newActivities
				},
				kcals: block?.kcals + newKcal,
			};

			setBlock(newBlock);

			let newElems = infoElements;
			newElems.push({
				number: newNumber,
				type: 'Activity',
				fullName: activity?.fullName,
				_id: activity?._id,
				lvl: activity?.lvl,
				time: activity?.time,
				kcals: activity?.kcals,
			})
			setInfoElements(newElems);
		}
		setRefresh(true);
	}

	function addNewMeal(isFood) {
		setOpenEditMealDialog(true);
		setSelectedMeal(null);
		setIsFood(isFood);
		setRefresh(true)
	}

	function addNewActivity() {
		setOpenEditActivityDialog(true);
		setSelectedActivity(null);
		setRefresh(true);
	}
	
	if (isLoadingData) return (
		<>
			<Dialog disableBackdropClick={true}
				fullWidth={true}
				open={open}
				onClose={() => setOpen(false)}
				maxWidth="lg"
				aria-labelledby="table-dialog"
			>
				<DialogContent>
					
				<ContentSkeleton />
				</DialogContent>
			</Dialog>
		</>
)
	return (
		<Dialog disableBackdropClick={true}
			fullWidth={true}
			open={open}
			onClose={() => setOpen(false)}
			maxWidth="lg"
			aria-labelledby="table-dialog"
		>
			<DialogTitle id="table-dialog">{title}</DialogTitle>
			<DialogContent>
				{meal && (
					<>
						<Autocomplete
							filterSelectedOptions
							disablePortal
							disableClearable
							options={mealOptions}
							getOptionLabel={(option) =>
							option.fullName
							}
							value={mealOptions.find(option => option._id === block?.intake?.isSnack) || null}
							onChange={(event, selected) => {
								let newBlock = block;
								newBlock.intake = newBlock.intake || {};
								newBlock.intake.isSnack = selected?._id;
								setBlock({
									...newBlock,
								});
								
								// setRefresh(true);
							}}
							renderInput={(params) => (
							<TextField
								{...params}
								margin="normal"
								variant="standard"
								InputLabelProps={{
								shrink: true,
								}}
								required
								placeholder="Select type of block"
								label={"Type of block"}
							/>
							)}
						/>
					</>
				)}

				{!meal && (
					<>
						<h2>Type: Exercise</h2>
					</>
				)}
				
				<br />
				<MultilanguageTabBlock
					multilanguageTabContent={renderMultilanguageTabContent}
				/>
				<Editor
					disabled={readOnly}
					body={editorBody || ""}
					setBody={(new_body) => {
						handleChangeEditor("description", new_body, false)}
					}
					className="max-height"
					placeholder={"Enter block description here..."}
					name="Description"
				/>
				
				{!meal && (
					<>
						<Row>
							<Col>
								<TextField
									id={`kcals`}
									label="Kcals"
									InputProps={{
										inputProps: {
											min: 0
										}
									}}
									inputProps={{ readOnly: true }}
									value={formatFloat(block?.kcals) || 0}
									margin="normal"
									variant="standard"
									type="number"
									className='readonly'
								/>
							</Col>
						</Row>
						
						<Card>
							<CardBody>
								<Row>
									<Col><h3>Activities</h3></Col>
									<Col></Col><Col></Col><Col></Col>
									<Col>
										<button
											type="button"
											className="btn btn-primary"
											style={{marginLeft: 10}}
											onClick={() => addNewActivity()}
										>
											Add activity
										</button>
									</Col>
								</Row>
								{!refresh &&
									infoElements?.length > 0 && (
										<Table
											columns={activitiesTable}
											data={getInfoElementsData(infoElements)}
										/>
									)}
							</CardBody>
							<EditBlockActivityDialog
								open={openEditActivityDialog}
								setOpen={setOpenEditActivityDialog}
								data={selectedActivity}
								readOnly={false}
								activities={activities}

								onSave={(activity, activityAnt) => {
									saveNewActivity(activity, activityAnt);
								}}
							/>
						</Card>
					</>
				)}

				{meal && (
					<>
						<Row>
							<Col sm={4}>
								<TextField
									id={`kcals`}
									label="Kcals"
									InputProps={{
										inputProps: {
											min: 0
										}
									}}
									inputProps={{ readOnly: true }}
									value={formatFloat(block?.kcals) || 0}
									margin="normal"
									variant="standard"
									type="number"
									className='readonly'
								/>
							</Col>
							<Col sm={2}>
								<TextField
									id={`proteins`}
									label="Proteins"
									InputProps={{
										inputProps: {
											min: 0
										}
									}}
									inputProps={{ readOnly: true }}
									value={formatFloat(block?.proteins) || 0}
									margin="normal"
									variant="standard"
									type="number"
									className='readonly'
								/>
							</Col>
							<Col sm={2}>
								<TextField
									id={`carbohydrates`}
									label="Carbohydrates"
									InputProps={{
										inputProps: {
											min: 0
										}
									}}
									inputProps={{ readOnly: true }}
									value={formatFloat(block?.carbohydrates) || 0}
									margin="normal"
									variant="standard"
									type="number"
									className='readonly'
								/>
							</Col>
							<Col sm={2}>
								<TextField
									id={`fats`}
									label="Fats"
									InputProps={{
										inputProps: {
											min: 0
										}
									}}
									inputProps={{ readOnly: true }}
									value={formatFloat(block?.fats) || 0}
									margin="normal"
									variant="standard"
									type="number"
									className='readonly'
								/>
							</Col>
							<Col sm={2}>
								<TextField
									id={`fiber`}
									label="Fiber"
									InputProps={{
										inputProps: {
											min: 0
										}
									}}
									inputProps={{ readOnly: true }}
									value={formatFloat(block?.fiber) || 0}
									margin="normal"
									variant="standard"
									type="number"
									className='readonly'
								/>
							</Col>
						</Row>
						
						{block?.intake?.isSnack === '1' && (
							<FormControlLabel
								control={
									<Checkbox
										checked={block?.intake?.optional}
										onChange={() =>
											setBlock({
												...block,
												intake: {
													...block?.intake,
													optional: !block?.intake?.optional,
												}
											})
										}
										name="checkActive"
									/>
								}
								label="Optional"
							/>
						)}
						<Card>
							<CardBody>
								<Row>
									<Col><h3>Meals</h3></Col>
									<Col></Col><Col></Col><Col></Col>
									<Col>
										<button
											type="button"
											className="btn btn-primary"
											style={{marginLeft: 10}}
											onClick={() => addNewMeal(true)}
										>
											Add food
										</button>
										<button
											type="button"
											className="btn btn-primary"
											style={{marginLeft: 10}}
											onClick={() => addNewMeal(false)}
										>
											Add recipe
										</button>
									</Col>
								</Row>
								{!refresh &&
									infoElements?.length > 0 && (
										<Table
											columns={elementsTable}
											data={getInfoElementsData(infoElements)}
										/>
									)}
							</CardBody>
							<EditBlockFoodDialog
								open={openEditMealDialog}
								setOpen={setOpenEditMealDialog}
								data={selectedMeal}
								readOnly={false}
								isFood={isFood}
								foods={foods}
								foodPlates={foodPlates}

								onSave={(meal) => {
									if (isFood) saveNewFood(meal);
									else saveNewRecipe(meal);
								}}
							/>
						</Card>
						
					</>
				)}			

				<br />
			</DialogContent>
			<DialogActions>
				<Button
					variant="outlined"
					style={{ marginRight: "15px" }}
					onClick={() => {
						setOpen(false);
						setBlock(getEmptyStructureBlock());
						setEditorBody("");
						setInfoElements([]);
					}}
				>
					Close
				</Button>
				<Button
					variant="outlined"
					color="primary"
					onClick={() => {
						if (meal && (block?.intake?.isSnack === undefined ||
							block?.intake?.isSnack === null)) {
							alertError({
								error: null,
								customMessage:
									"The type is required",
							});
						} else if (!block?.name) {
							alertError({
								error: null,
								customMessage:
									"The title is required",
							});
						} else {
							setOpen(false);

							let saveBlock = block;
							if (meal) {
								saveBlock.intake.isSnack = (saveBlock.intake.isSnack === '1')
							}

							onSave(saveBlock, meal);
							setBlock(getEmptyStructureBlock());
							setEditorBody("");
							setInfoElements([]);
						}
					}}
				>
					Save
				</Button>
			</DialogActions>
		</Dialog>
	);
	
};

export default EditStructureBlockDialog;