import { uploadSingleFile } from "../files";
import { API, authClient } from "../index";

export const postCompetition = async (competition, image) => {
	if (image) {
		const response = await uploadSingleFile(image, "competitions");
		competition.imageURL = response.data.fileURL;
	}
	return authClient().post(`${API}/competition`, competition);
};

export const getCompetitions = () => {
	return authClient().get(`${API}/competition?getDisabled=true`);
};

export const getCompetitionById = (id) => {
	return authClient().get(`${API}/competition/${id}`);
};

export const updateCompetition = async (id, competition, image) => {
	if (image) {
		const response = await uploadSingleFile(image, "competitions");
		competition.imageURL = response.data.fileURL;
	}
	return authClient().put(`${API}/competition/${id}`, competition);
};

export const changeStatusCompetition = async (id, active) => {
	return authClient().put(`${API}/competition/change-status/${id}`, {
		active,
	});
};

export const deleteCompetition = (id) => {
	return authClient().delete(`${API}/competition/${id}`);
};

// Count all competitions
export const countCompetitions = () => {
	  return authClient().get(`${API}/competition/count/`)
  }