import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import dayGridPlugin from "@fullcalendar/daygrid";
import FullCalendar from "@fullcalendar/react";

import { combineDateAndTime, formatDate, formatTime } from "./helpers";
import { activityCalcs, foodCalcs, recipeCalcs } from "../../../utils/structuresCalcs";
import { formatFloat } from "../../../utils/helpers";

export default function FullCalendarView({setDate, setWeek, userDiets, refresh, blocks, selectedBlock}) {

  const [events, setEvents] = useState(0);
  const [totalKcalsByDate, setTotalKcalsByDate] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      console.log(userDiets)
      const { events, totalKcalsByDate } = await getInfoDiets(userDiets);
      setEvents(events);
      setTotalKcalsByDate(totalKcalsByDate);
    };

    if (userDiets) fetchData();
  }, [userDiets, refresh]);

  const getFormattedDate = (date) => {
    const localDate = new Date(date);
    
    const year = localDate.getFullYear();
    const month = String(localDate.getMonth() + 1).padStart(2, '0');
    const day = String(localDate.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  };

  async function getInfoDiets(userDiets) {
    let data = [];
    let totalKcalsByDate = {};

    for (let i = 0; i < userDiets?.length; ++i) {
      const actD = userDiets[i];

      const startDate = getFormattedDate(actD?.date);
      let dailyKcals = 0;

      if (actD?.diet?.blocks) {
        for (let block of actD?.diet?.blocks) {
          let nutritionalValues = {kcals: 0, carbohydrates: 0, fiber: 0, fats: 0, proteins: 0};
          if (block?.isIntake) {
            if (block?.intake?.recipes?.length > 0) {
              const recipeKcals = await Promise.all(
                block.intake.recipes.map(async (recipe) => {
                  try {
                    const res = await recipeCalcs(recipe.recipe);
                    return res;
                  } catch (error) {
                    console.log(error);
                    return 0;
                  }
                })
              );
              nutritionalValues = recipeKcals.reduce((total, nt) => {
                return {
                  kcals: total.kcals + nt.kcals, 
                  carbohydrates: total.carbohydrates + nt.carbohydrates, 
                  fiber: total.fiber + nt.fiber, 
                  fats: total.fats + nt.fats, 
                  proteins: total.proteins + nt.proteins
                }}, {
                  kcals: nutritionalValues.kcals, 
                  carbohydrates: nutritionalValues.carbohydrates, 
                  fiber: nutritionalValues.fiber, 
                  fats: nutritionalValues.fats, 
                  proteins: nutritionalValues.proteins
                });
            }
            if (block?.intake?.foods?.length > 0) nutritionalValues = block.intake?.foods?.reduce((total, food) => {
              const nt = foodCalcs(food);
              return {
                kcals: total.kcals + nt.kcals, 
                carbohydrates: total.carbohydrates + nt.carbohydrates, 
                fiber: total.fiber + nt.fiber, 
                fats: total.fats + nt.fats, 
                proteins: total.proteins + nt.proteins
              }}, {
                kcals: nutritionalValues.kcals, 
                carbohydrates: nutritionalValues.carbohydrates, 
                fiber: nutritionalValues.fiber, 
                fats: nutritionalValues.fats, 
                proteins: nutritionalValues.proteins
              });
            dailyKcals += nutritionalValues.kcals;
          } else {
            if (block?.exercise?.activities?.length > 0) dailyKcals -= block?.exercise?.activities?.reduce((total, activity) => 
              total + activityCalcs(activity).kcals, 0);        
          }
          let elem = {
            start: startDate,
            title: '',
            extendedProps: {
              type: (block?.isIntake? block?.intake?.isSnack? "Snack" : "Main" : "Exercise"),
              details: block?.isIntake 
                ? formatIntakeDetails(block, nutritionalValues) 
                : formatExerciseDetails(block),
              numBlock: block?.number
            }
          };
          data.push(elem)
        }
      }

      totalKcalsByDate[startDate] = dailyKcals;
    }

    return { events: data, totalKcalsByDate };
  }

  function formatIntakeDetails(block, nutritionalValues) {
    const intake = block?.intake;
    let details = '';
    const styleValues='border: none; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;'

    details += `<div style="white-space: normal; word-wrap: break-word;">
          <strong>${block?.name}</strong>
          <br><strong>${block?.description? block.description : "There is no description"}</strong>
          <table style="width: 100%; border-collapse: collapse;">
          <thead>
            <tr>
              <th style="${styleValues}">KCls</th>
              <th style="${styleValues}">CHO</th>
              <th style="${styleValues}">PRO</th>
              <th style="${styleValues}">FAT</th>
              <th style="${styleValues}">Fiber</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style="text-align: center; border: none;">${formatFloat(nutritionalValues.kcals)}</td>
              <td style="text-align: center; border: none;">${formatFloat(nutritionalValues.carbohydrates)}</td>
              <td style="text-align: center; border: none;">${formatFloat(nutritionalValues.proteins)}</td>
              <td style="text-align: center; border: none;">${formatFloat(nutritionalValues.fats)}</td>
              <td style="text-align: center; border: none;">${formatFloat(nutritionalValues.fiber)}</td>
            </tr>
          </tbody>
        </table></div>`;
  
    return details;
  }
  
  function formatExerciseDetails(block) {
    const exercise = block?.exercise;
    return exercise?.activities?.length > 0 
      ? exercise.activities.map(activity => 
        `<div style="white-space: normal; word-wrap: break-word;">
        ${activity?.activity?.fullName?.es}<br>Duration: ${activity?.time} s</div>`
      ).join('<br>') 
      : 'No activities recorded';
  }

  function dietContent(info) {
    const { type, details } = info.event.extendedProps;

        return (
          <div style={{
            backgroundColor: type === 'Snack' ? 'rgba(254,110,95, 1)' : 
                              type === 'Main' ? 'rgba(189,215,236, 1)' : 
                              'rgba(166,217,111, 1)',
            border: '1px solid #ccc',
            padding: '5px',
            borderRadius: '5px'
          }}>
            <div><strong>{type}</strong></div>
            <div dangerouslySetInnerHTML={{ __html: details }} />
          </div>
        );
  }

  function dayCellContent(arg) {
    const formattedDate = getFormattedDate(arg.date);
    const totalKcals = totalKcalsByDate[formattedDate] || 0;

    return (
      <div>
        <div>{arg.dayNumberText}</div>
        <div style={{ fontSize: '12px', fontWeight: 'bold', color: 'black' }}>
          Total Kcals: {formatFloat(totalKcals)} 
        </div>
      </div>
    );
  }

  return (
    <FullCalendar
      plugins={[interactionPlugin, dayGridPlugin]}
      initialView="dayGridWeek"
      headerToolbar={{
        left: "prev,next today",
        center: "title",
        right: ""
      }}
      firstDay={1}
      editable={true}
      selectable={true}
      //height={700}
      eventStartEditable={false}
      eventDurationEditable={false}
      events={events}
      eventContent={(info) => dietContent(info)}
      dateClick={(info) => {
        setDate({date: info.date, dateStr: info.dateStr})
      }}
      eventClick={(info) => {
        setDate({date: info.event.start, dateStr: getFormattedDate(info.event.start), numBlock: info.event.extendedProps.numBlock})
      }}
      datesSet={(info) => { 
        setWeek(info.startStr)
      }}
      dayCellContent={dayCellContent}
    />
  );
}