import React, { useEffect, useState } from "react";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { alertError } from "../../../utils/logger";
import { getEmptySchedule } from "../../../utils/helpers";
import { daysOfWeek, scheduleOptions } from "../../../constants/utils.constants";

const scheduleOptionsAutocomplete = scheduleOptions.map((option) => ({
	_id: option,
	fullName: option,
}));

const EditScheduleDialog = (props) => {
	const { title, data, open, setOpen, onSave, readOnly } = props;

	const [schedule, setSchedule] = useState(getEmptySchedule());

	useEffect(() => {
        console.log(open);
        console.log(data);
        
		if (open && data) {
			setSchedule(data);
		} else {
			setSchedule(getEmptySchedule());
		}
	}, [data, open]);

	const handleChange = (day) => (event, selected) => {
		setSchedule({ ...schedule, [day]: selected ? selected._id : "" });
	};

	const renderDaySelect = (day) => (
		<div key={day}>
			<Autocomplete
				filterSelectedOptions
				disablePortal
				disableClearable
				options={scheduleOptionsAutocomplete}
				getOptionLabel={(option) => option.fullName}
				value={
					scheduleOptionsAutocomplete.find(
						(option) => option._id === schedule[day]
					) || null
				}
				onChange={handleChange(day)}
				disabled={readOnly}
				renderInput={(params) => (
					<TextField
						{...params}
						variant="outlined"
						margin="normal"
						InputLabelProps={{
							shrink: true,
						}}
						required
						placeholder="Select working hours"
						label={`Select schedule for ${day}`}
					/>
				)}
			/>
		</div>
	);

	return (
		<Dialog disableBackdropClick={true}
			fullWidth={true}
			open={open}
			onClose={() => {
				setOpen(false);
				setSchedule(getEmptySchedule());
			}}
			maxWidth="md"
			aria-labelledby="schedule-dialog"
		>
			<DialogTitle id="schedule-dialog">{title}</DialogTitle>
			<DialogContent>
				{daysOfWeek.map((day) => renderDaySelect(day))}
			</DialogContent>
			<DialogActions>
				<Button
					variant="outlined"
					onClick={() => {
						setOpen(false);
						setSchedule(getEmptySchedule());
					}}
				>
					Close
				</Button>
				<Button
					variant="outlined"
					color="primary"
					onClick={() => {
						if (Object.values(schedule).some((value) => value === "")) {
							alertError({
								error: null,
								customMessage: "All days must have a selected schedule.",
							});
						} else {
							onSave(schedule);
							setOpen(false);
						}
					}}
				>
					Save
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default EditScheduleDialog;
