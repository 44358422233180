import {
	Button,
	Card,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Tooltip,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { getFoods } from "../../../../api/food";
import { CardBody } from "../../../../_metronic/_partials/controls";
import { ArrowDownward, ArrowUpward, Delete, Edit } from "@material-ui/icons";
import Table, { buttonsStyle } from "../../tables/table";
import { alertError } from "../../../../utils/logger";
import MultilanguageTabBlock from "../../MultilanguageTabBlock";
import EditTramFoodDialog from "./EditTramFoodDialog";

function getEmptyFood() {
	return {
        food: null,
		name: null,
        quantity: null,
        number: null,
        observations: ""
	};
}

const EditPatientTramIntakeDialog = (props) => {
	const { title, data, open, setOpen, onSave, readOnly, competitionId, userId } = props;

	const [food, setFood] = useState(null);
	const [foods, setFoods] = useState([]);
	const [foodOptions, setFoodOptions] = useState([]);

	const [intake, setIntake] = useState([]);
	const [selectedFood, setSelectedFood] = useState(null);
	const [openEditTramFoodDialog, setOpenEditTramFoodDialog] = useState(false);
	const [tram, setTram] = useState(getEmptyTram());

	const [refresh, setRefresh] = useState(false);

	function getEmptyTram() {
		return {
			_id: undefined,
			numTram: '',
			name: '',
			idClient: userId,
			idCompetition: competitionId,
			foods: [],
			extrInfo: ''
		};
	}

	useEffect(() => {
		if (!open || !data) {
			setTram(getEmptyTram());
		}
		else {
			setTram(data);
			setIntake(getDataFoods(data?.foods));
		}
	}, [data, open, competitionId]);

	useEffect(() => {
		if (foods?.length === 0) {
			getFoods()
				.then((res) => {
					if (res.status === 200) {
						setFoods(res.data);
						actualizeFoodOptions(res.data);
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not get foods.",
					});
				});
		}
	}, [data, tram])

    useEffect(() => {
        setRefresh(false)
    }, [refresh])

	const intakeColumns = [
		{
			dataField: "name",
			text: "Food"
		},
		{
			dataField: "grams",
			text: "grams",
			align: "center",
			headerAlign: "center"
		},
		{
			dataField: "observations",
			text: "observations",
			align: "center",
			headerAlign: "center"
		},
		{
			dataField: "number",
			text: "",
			formatter: intakesButtonsFormatter,
			align: "right"
		}
	]

	function intakesButtonsFormatter(cell) {
        const food = intake?.find((x => x.number === cell));

		return (
			<>
				<Tooltip title="Edit food">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
							setOpenEditTramFoodDialog(true);
							setSelectedFood(food);
						}}
					>
						<Edit />
					</Button>
				</Tooltip>
				<Tooltip title="Move up">
					<Button
						size="small"
						style={buttonsStyle}
						disabled={cell === 0}
						onClick={() => elementMove(cell, cell-1)}
					>
						<ArrowUpward />
					</Button>
				</Tooltip>
				<Tooltip title="Move down">
					<Button
						size="small"
						disabled={
							cell >= intake.length - 1
						}
						style={buttonsStyle}
						onClick={() => elementMove(cell, cell+1)}
					>
						<ArrowDownward />
					</Button>
				</Tooltip>
				<Tooltip title="Delete food">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
							const index = intake.findIndex((x) => x.number === cell);
							let newIntake = intake;
							newIntake.splice(index, 1);
							for (let i = index; i < newIntake?.length; ++i) newIntake[i].number--;
							setIntake(newIntake);
							setTram({
								...tram,
								foods: newIntake
							})
							setRefresh(true);
						}}
					>
						<Delete />
					</Button>
				</Tooltip>
				
			</>
		)
	}

	function elementMove(index, newIndex) {
		let elems = intake;
		let elem1 = intake[index];
		let elem2 = intake[newIndex];
		elem1.number = newIndex;
		elem2.number = index;
		elems[newIndex] = elem1;
		elems[index] = elem2;


		setIntake(elems);
		setTram({
			...tram,
			foods: intake
		})
		setRefresh(true);
	}

	function getDataFoods(foods) {
		let data = [];

		for (let i = 0; i < foods?.length; ++i) {
			let elem = {};
			const actF = foods[i];

			elem.food = actF?.food;
			elem.name = actF?.name;
			elem.quantity = actF?.quantity;
			elem.observations = actF?.observations;
			elem.number = actF?.number;
			elem.grams = actF?.grams || 0;
			elem.selectedPortion = (actF?.selectedPortion >= 0)? actF.selectedPortion : -1;

			data.push(elem)
		}

		return data;
	}

	function actualizeFoodOptions(foods) {
		let data = [];

		for (let i = 0; i < foods?.length; ++i) {
			let elem = {};
			const actF = foods[i];

			elem._id = actF._id;
			elem.name = actF.fullName.es;

			data.push(elem)
		}

		setFoodOptions(data);
	}

	const renderMultilanguageTabContent = (lang) => {
		return (
			<>
				<br />
			</>
		);
	};

	return (
		<Dialog disableBackdropClick={true} 
			fullWidth={true}
			open={open}
			onClose={() => setOpen(false)}
			maxWidth="lg"
			aria-labelledby="table-dialog"
		>
			<DialogTitle id="table-dialog">{title}</DialogTitle>
			<DialogContent>
				<MultilanguageTabBlock
					multilanguageTabContent={renderMultilanguageTabContent}
				/>
				<br />
				<Card>
					<CardBody>
						<Row>
							<Col><h3>Intakes</h3></Col>
							<Col></Col><Col></Col><Col></Col>
							<Col>
								<button
									type="button"
									className="btn btn-primary"
									style={{marginLeft: 10}}
									onClick={() => {
										setOpenEditTramFoodDialog(true);
										setSelectedFood({
											...getEmptyFood(),
											number: intake?.length
										});
									}}
								>
									Add food
								</button>
							</Col>
						</Row>
						{!refresh && (
								<Table
									columns={intakeColumns}
									data={intake}
								/>
						)}
					</CardBody>
					<EditTramFoodDialog 
						open={openEditTramFoodDialog}
						setOpen={setOpenEditTramFoodDialog}
						foodOptions={foodOptions}
						data={selectedFood}
						onSave={(food) => {
							let newIntake = [
								...intake
							]

							const index = intake?.findIndex((x) => x.number === food.number);

							if (index === -1) newIntake.push(food);
							else newIntake[index] = food;
							
							setIntake(newIntake);
							setTram({
								...tram,
								foods: newIntake
							})
							setRefresh(true);
						}}
					/>
				</Card>
			</DialogContent>
			<DialogActions>
				<Button
					variant="outlined"
					style={{ marginRight: "15px" }}
					onClick={() => {
						setOpen(false);
						setFood(getEmptyTram());
					}}
				>
					Close
				</Button>
				<Button
					variant="outlined"
					color="primary"
					onClick={() => {
						setOpen(false);
						onSave(tram);
						setTram(getEmptyTram());
					}}
				>
					Save
				</Button>
			</DialogActions>
		</Dialog>
	);
};
export default EditPatientTramIntakeDialog;