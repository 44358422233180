import { Button, Tooltip } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ToggleOffIcon from "@material-ui/icons/ToggleOff";
import ToggleOnIcon from "@material-ui/icons/ToggleOn";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { getNonEmpty } from "../../../../utils/helpers";
import { alertError, alertSuccess } from "../../../../utils/logger";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import Table, {
	buttonsStyle,
	dateFormatter,
} from "../../../components/tables/table";
import { deleteUserDocument, getUserDocumentsByUserId, postUserDocuments, updateUserDocuments } from "../../../../api/userDocuments";
import EditPatientDocument from "./EditPatientDocument";

export default function EditPatientDocuments(props) {
  const { userId } = props;
	const [data, setData] = useState(null);
  const [selectedDocument, setSelectedDocument] = useState(null);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(-1);
  const [openEditPatientDocument, setOpenEditPatientDocument] = useState(false);
  const [newDocument, setNewDocument] = useState(false);
	const [refresh, setRefresh] = useState(false);
	const history = useHistory();
	const user = useSelector(
		(store) => store.authentication?.user,
		shallowEqual
	);

  function getEmptyUserDocuments() {
    return {
      userId: userId,
      documents: [],
      active: true
    } 
  }

  function getEmptyDocument() {
    const newDate = new Date();
    const formatDate = new Date(newDate.getTime() - newDate.getTimezoneOffset() * 60000).toISOString().split('.')[0];
    return {
      _id: data?.documents?.length || 0,
      title: "",
      description: "",
      createdAt: formatDate,
      fileURL: null,
      active: true
    }
  }

  useEffect(() => {
      if (!data) getUserDocumentsByUserId(userId)
        .then((res) => {
          if (res.status === 200) {
            setData(res.data);
          }
        })
        .catch((error) => {
          if (error.request.status !== 404) {
            alertError({
              error: error,
              customMessage: "Could not get user documents"
            })
          } else {
            setData(getEmptyUserDocuments());
          }
        })
  }, [userId])

  useEffect(() => {
    setRefresh(false);
  }, [refresh])

	const columns = [
		{
			dataField: "name",
			text: "Full name",
			sort: true,
		},
		{
			dataField: "createdAt",
			text: "Created at",
			formatter: dateFormatter,
			sort: true,
		},
		{ 
      dataField: "_id", 
      text: "", 
      align: 'right',
      formatter: buttonFormatter
    },
	];

  function buttonFormatter(cell, row) {
		const index = data?.documents?.findIndex((item) => item.title === row.name);
    const elem = (index !== -1)? data?.documents[index] : null;
		return (
			<>
				<Tooltip title="Edit">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
              setSelectedDocument(elem);
              setOpenEditPatientDocument(true);
              setNewDocument(false);
            }}
					>
						<EditIcon />
					</Button>
				</Tooltip>
				{user?.role.includes("admin") && (
					<>
						<Tooltip title={elem?.active ? "Disable" : "Enable"}>
							<Button
								style={buttonsStyle}
								size="small"
								onClick={() => {
                  let newDocuments = [...data?.documents];
                  newDocuments[index].active = !elem?.active;
                  setData({
                    ...data,
                    documents: newDocuments
                  });
                  setRefresh(true);
								}}
							>
								{elem?.active ? (
									<ToggleOffIcon />
								) : (
									<ToggleOnIcon style={{ color: "red" }} />
								)}
							</Button>
						</Tooltip>
						<Tooltip title="Delete">
							<Button
								style={buttonsStyle}
								size="small"
								onClick={() => {
									setOpenConfirmDialog(2);
                  setSelectedDocument(elem);
								}}
							>
								<DeleteIcon />
							</Button>
						</Tooltip>
					</>
				)}
			</>
		);
	}

  function getDataDocuments(docs) {
    let data = [];

    for (let i = 0; i < docs?.length; ++i) {
      let elem = {};
      const actD = docs[i];
      
      elem.id = elem?._id;
      elem.name = actD?.title || "No title";
      elem.createdAt = actD?.createdAt || "";

      data.push(elem);
    }

    return data;
  }

  async function saveDocument(data, document, file) {
    let index = data?.documents?.findIndex((x) => x._id === document?._id)
    let newData = data;
    if (index !== -1) newData.documents[index] = document;
    else {
      newData.documents.push(document);
      index = newData.documents.length-1;
    }
    if (data?._id) {
      await updateUserDocuments(data._id, newData, index, file)
        .catch((error) => {
          alertError({
            error: error,
            customMessage: "Could not update user documents"
          })
          return false;
        });
      setData({...newData});
    } else {
      const res = await postUserDocuments(newData, index, file)
        .catch((error) => {
          alertError({
            error: error,
            customMessage: "Could not update user documents"
          })
          return false;
        });
      setData({...newData, _id: res.data._id});
    }
    alertSuccess({
      title: "Saved!",
      customMessage: "Document saved succesfully!"
    })
    return true;
  }

  async function deleteDocument(data, document) {
    let index = data?.documents?.findIndex((x) => x._id === document?._id)
    let newData = data;
    if (index !== -1) newData.documents.splice(index, 1);
    else {
      alertError({
        error: "Error!",
        customMessage: "Could not delete document"
      })
    }
    if (data?._id) {
      await deleteUserDocument(data._id, data, index)
        .catch((error) => {
          alertError({
            error: error,
            customMessage: "Could not delete document"
          })
          return false;
        });
    } else {
      alertError({
        error: "Error!",
        customMessage: "Could not delete document"
      })
    }
    alertSuccess({
      title: "Saved!",
      customMessage: "Document deleted succesfully!"
    })
    setData({...newData});
    setRefresh(true);
    return true;
  }

  if (openEditPatientDocument) return (
    <EditPatientDocument 
    data={selectedDocument}
    setOpen={setOpenEditPatientDocument}
    newDocument={newDocument}
    onSave={async (document, file) => {
      return await saveDocument(data, document, file);
    }}
    onDelete={async (document) => {
      return await deleteDocument(data, document)
    }}
    />
  )
	return (
		<>
			<Card>
				<CardHeader title="Documents">
					<CardHeaderToolbar>
						<button
							type="button"
							className="btn btn-primary"
							onClick={() => {
                setSelectedDocument(getEmptyDocument());
                setOpenEditPatientDocument(true);
                setNewDocument(true);
              }}
						>
							Add new
						</button>
					</CardHeaderToolbar>
				</CardHeader>
				<CardBody>
					{!refresh && (<Table data={getDataDocuments(data?.documents)} columns={columns} />)}
					<ConfirmDialog
						title={"Are you sure you want to remove this document?"}
						open={openConfirmDialog === 2}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							deleteDocument(data, selectedDocument)
						}}
					/>
				</CardBody>
			</Card>
		</>
	);
}
