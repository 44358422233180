import {
	Button,
	TextField,
	Tooltip,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	createMuiTheme,
	FormControlLabel,
	Checkbox,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
	Card,
	CardBody,
} from "../../../../_metronic/_partials/controls";
import { alertError } from "../../../../utils/logger";
import ConfirmDialog from "../ConfirmDialog";
import { useSkeleton } from "../../../hooks/useSkeleton";
import { Col, Row } from "react-bootstrap";
import { getTramsCompetitionUser } from "../../../../api/tramXuser";
import Table, { buttonsStyle } from "../../tables/table";
import { ArrowDownward, ArrowUpward, Delete, Edit, LocalDining } from "@material-ui/icons";
import EditPatientTramIntakeDialog from "../userCompetitions/EditPatientTramIntakeDialog";
import MyAutocomplete from "../../MyAutocomplete";
import { getStructureById, getStructures } from "../../../../api/structure";
import EditStructureBlockDialog from "../structures/EditStructureBlockDialog";
import _ from 'lodash';
import { blockCalc, getDataBlocks, reCalcNutritionalValues } from "../../../../utils/structuresCalcs";
import { formatFloat } from "../../../../utils/helpers";

// Create theme for delete button (red)
const theme = createMuiTheme({
	palette: {
		secondary: {
			main: "#F64E60",
		},
	},
});

export default function EditPatientDietDialog(props) {
	const { title, data, open, setOpen, onSave, userId, 
		competitions, userCompetitions, setUserCompetitions, 
		changes, setChanges, tramsXuser, setTramsXuser, date,
		openEditBlockDialog, setOpenEditBlockDialog,
		selectedBlock, setSelectedBlock, isMeal, setIsMeal,
		blocks, setBlocks, userDiet,
		setUserDiet, structure, setStructure, saveDiet } = props;

	const history = useHistory();

	const [competition, setCompetition] = useState(getEmptyCompetition());
    const [competitionOptions, setCompetitionOptions] = useState([]);
	const [selectedCompetition, setSelectedCompetition] = useState(null);

    const [openEditTramUserDialog, setOpenEditTramUserDialog] = useState(false);
    const [selectedTram, setSelectedTram] = useState(null);

    const [structures, setStructures] = useState([]);
    const [structuresOptions, setStructuresOptions] = useState([]);


    const [openConfirmDialog, setOpenConfirmDialog] = useState(-1);
    const [selectedStructure, setSelectedStructure] = useState(null);
    const [structureHasChanged, setStructureHasChanged] = useState(false);

	const [dataBlocks, setDataBlocks] = useState([]);
	const [dataNt, setDataNt] = useState({kcals: 0, carbohydrates: 0, proteins: 0, fats: 0, fiber: 0});

	const [refresh, setRefresh] = useState(false);
	
	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	function getEmptyCompetition() {
		return {
			_id: null,
			competitionId: null,
			competitionName: null,
			date: date?.date?.toISOString(),
			finished: false
		};
	}

	function getEmptyTram() {
		return {
			_id: undefined,
			numTram: '',
			name: '',
			idClient: userId,
			idCompetition: competition?.competitionId,
			foods: [],
			extrInfo: ''
		};
	}

	useEffect(() => {
		if (!data) {
			setUserDiet(null);
			setStructure(null);
			setBlocks([]);
		}
		else {
			const dataAux = _.cloneDeep(data);
			setUserDiet(dataAux);
			setStructure(dataAux?.diet);
			setBlocks(dataAux?.diet?.blocks);
		}
		disableLoadingData();
	}, [data])

	useEffect(() => {
		if (date) {
			const dt = date.date.toISOString();
			const comp = userCompetitions?.competitions.find((x) => x.date === dt);
			if (comp) {
				setCompetition(comp);
				actualizeTramsData(competition);
			}
			else setCompetition(getEmptyCompetition());
		}
	}, [date])

	useEffect(() => {
		setRefresh(false);
	}, [refresh])

    useEffect(() => {
        if (structures?.length === 0)
            getStructures()
                .then((res) => {
                    if (res.status === 200) {
                        setStructures(res.data);
                        actualizeStructuresOptions(res.data);
                    }
                })
                .catch((error) => {
                    alertError({
                        error: error,
                        customMessage: "Could not get structures.",
                    });
                });
    }, [structures])

    useEffect(() => {
        if (competitions?.length !== 0) actualizeCompetitionOptions(competitions)
    }, [competitions])

    useEffect(() => {
        if (structure) {
            setStructureHasChanged(true);
            setChanges(changes+1);
        }
    }, [structure])

	useEffect(() => {
		if (blocks) getData(blocks)
	}, [blocks])

    function actualizeTramsData(competition) {
        if (userId && date?.dateStr && competition?.competitionId) 
			getTramsCompetitionUser(competition?.competitionId, userId, date.dateStr)
				.then((res) => {
					if (res.status === 200) {
						setTramsXuser(res.data);
						disableLoadingData();
					}
				})
				.catch((error) => {
					console.log("No hay tramos")
				});
    }

	const tramsTable = [
        {
            dataField: "name",
            text: "title"
        },
        {
            dataField: "foods",
            text: "grams",
            align: 'center',
            headerAlign: "center",
            formatter: quantityFormatter
        },
        {
            dataField: "foods",
            text: "food",
            align: 'center',
            headerAlign: "center",
            formatter: foodsFormatter
        },
        {
            dataField: "foods",
            text: "observations",
            align: 'center',
            headerAlign: "center",
            formatter: observationsFormatter
        },
        {
            dataField: "number",
            text: "",
            formatter: tramsButtonFormatter,
            align: "right",
        }
    ]

    function quantityFormatter(cell) {
        let text = "";

        if (cell?.length > 0) {
            text = cell[0].grams;
            for (let i = 1; i < cell?.length; ++i) {
                const actF = cell[i];
                text += "<br>" + actF.grams;
            }
        }
    
        return <span dangerouslySetInnerHTML={{ __html: text }} />;
    }

    function foodsFormatter(cell) {
        let text = "no intake";

        if (cell?.length > 0) {
            text = cell[0].name;
            for (let i = 1; i < cell?.length; ++i) {
                const actF = cell[i];
                text += "<br>" + actF.name;
            }
        }
    
        return <span dangerouslySetInnerHTML={{ __html: text }} />;
    }

    function observationsFormatter(cell) {
        let text = "";

        if (cell?.length > 0) {
            text = cell[0].observations;
            for (let i = 1; i < cell?.length; ++i) {
                const actF = cell[i];
                text += "<br>" + actF.observations;
            }
        }
    
        return <span dangerouslySetInnerHTML={{ __html: text }} />;
    }

	function tramsButtonFormatter(cell, row) {
        let tram = tramsXuser?.find((x => parseFloat(x.numTram) === parseFloat(cell)));
		if (!tram) tram = {...getEmptyTram(), name: row.name, numTram: row.number};

        return (
			<>
				{cell != 0 && (
					<> 
						<Tooltip title="Edit intake">
							<Button
								style={buttonsStyle}
								size="small"
								onClick={() => {
									setOpenEditTramUserDialog(true);
									setSelectedTram(tram);
								}}
							>
								<LocalDining />
							</Button>
						</Tooltip>
					</>
				) 
				}
				
			</>
		)
	}

	function actualizeCompetitionOptions(competitions) {
        let data = [];

		data.push({
			competitionId: -1,
			competitionName: "None"
		})

        for (let i = 0; i < competitions?.length; ++i) {
            let elem = {};
            const act = competitions[i];

            elem.competitionId = act._id;
            elem.competitionName = act.fullName.es;

            data.push(elem);
        }

        setCompetitionOptions(data);
    }

	function actualizeStructuresOptions(structures) {
		let data = [];

		for (let i = 0; i < structures?.length; ++i) {
			let elem = {}
			const act = structures[i];

			elem._id = act._id;
			elem.name = act.fullName.es;

			data.push(elem);
		}

		setStructuresOptions(data);
	}

    function getTramsTableData(tramsXuser) {
        function sortByNumber(a, b) {
            if (a.number > b.number) return 1;
            if (a.number < b.number) return -1;
            return 0;
        }

        let data = [];

        const comp = competitions.find((x) => x._id === competition.competitionId)
        const trams = comp?.trams;

        for (let i = 0; i < trams?.length; ++i) {
            let elem = {};
            const act = trams[i];
            const definedTram = tramsXuser.find((x) => parseInt(x.numTram) === act.number)
            
            elem.name = act.name;
            elem.number = act.number;
            elem.foods = definedTram?.foods || [];

            data.push(elem);
        }

        data.sort(sortByNumber)

        return data;
    }

	const handleChange = (element, lang) => (event) => {
		if (event.target.value === " ") return;
		if (!lang) {
			setUserDiet({...userDiet, [element]: event.target.value})
		}
		setRefresh(true);
	};

    const blockColumns = [
		{
			dataField: "number",
			text: "type",
			formatter: typeFormatter,
			style: styleFormatter,
		},
		{
			dataField: "kcals",
			text: "kcals",
			style: styleFormatter,
			formatter: numbersFormatter,
			align: 'center',
			headerAlign: 'center',
		},
		{
			dataField: "carbohydrates",
			text: "carbohydrates",
			style: styleFormatter,
			formatter: numbersFormatter,
			align: 'center',
			headerAlign: 'center',
		},
		{
			dataField: "proteins",
			text: "proteins",
			style: styleFormatter,
			formatter: numbersFormatter,
			align: 'center',
			headerAlign: 'center',
		},
		{
			dataField: "fiber",
			text: "fiber",
			style: styleFormatter,
			formatter: numbersFormatter,
			align: 'center',
			headerAlign: 'center',
		},
		{
			dataField: "fats",
			text: "fats",
			style: styleFormatter,
			formatter: numbersFormatter,
			align: 'center',
			headerAlign: 'center',
		},
		{
			dataField: "number",
			text: "",
			formatter: blocksButtonFormatter,
			align: 'right',
			headerStyle: { width: '200px'},
			style: styleFormatter
		}
	];

	function styleFormatter(cell, row) {
		const block = blocks?.find((x) => x.number === row.number);
		return (block?.isIntake? block?.intake.isSnack? 
			{ //SNACK
				backgroundColor: 'rgba(254,110,95, 1)'

			} : 
			{ //MAIN
				backgroundColor: 'rgba(189,215,236, 1)'
			} : 
			{ //ACTIVITY
				backgroundColor: 'rgba(166,217,111, 1)'
			});
	}

	function numbersFormatter(cell) {return cell.toFixed(1);}

    function typeFormatter(cell) {
		const block = blocks.find((x) => cell === x.number);
		return (block?.isIntake? block?.intake.isSnack? "Snack" : "Main" : "Exercise");
	}

    function blocksButtonFormatter(cell) {
		const index = blocks.findIndex((x) => x.number === cell);
		const block = blocks[index];

		return (
			<>
				<Tooltip title="Edit">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
							setOpenEditBlockDialog(true);
							setSelectedBlock(block);
							setIsMeal(block.isIntake);
						}}
					>
						<Edit />
					</Button>
				</Tooltip>
				<Tooltip title="Move up">
					<Button
						size="small"
						style={buttonsStyle}
						disabled={index === 0}
						onClick={() => blockMove(block, index, index-1)}
					>
						<ArrowUpward />
					</Button>
				</Tooltip>
				<Tooltip title="Move down">
					<Button
						size="small"
						disabled={
							index >= structure.blocks?.length - 1
						}
						style={buttonsStyle}
						onClick={() => blockMove(block, index, index+1)}
					>
						<ArrowDownward />
					</Button>
				</Tooltip>
				<Tooltip title="Delete">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
							let newBlocks = blocks;
							newBlocks.splice(index, 1);

							for (let i = index; i < newBlocks?.length; ++i) newBlocks[i].number = i;

							const data = reCalcNutritionalValues(newBlocks);

							const newStructure = {
								...structure,
								kcal: data.kcals,
								carbohydrates: data.carbohydrates,
								protein: data.protein,
								fats: data.fats,
								fatskg: data.fatskg,
								fatspercent: data.fatspercent,
								blocks: newBlocks,
							}

							saveDiet({...newStructure})
							setStructure({...newStructure});
							setBlocks([...newBlocks]);
							setRefresh(true);
						}}
					>
						<Delete />
					</Button>
				</Tooltip>
			</>
		)
	}

    function blockMove(block, index, newIndex) {
		let blocks = [
			...structure.blocks
		]
		const blockUp = blocks.find(
			(x) => x.number === newIndex
		)
		
		blocks[newIndex] = {
			...block,
			number: newIndex
		};
		blocks[index] = {
			...blockUp,
			number: index
		}

		const data = reCalcNutritionalValues(blocks);
		const newStructure = {
			...structure,
			kcal: data.kcals,
			carbohydrates: data.carbohydrates,
			protein: data.protein,
			fats: data.fats,
			fatskg: data.fatskg,
			fatspercent: data.fatspercent,
			blocks: blocks,
		}
		saveDiet(newStructure)
		setStructure(newStructure);
		setBlocks(blocks);
		setRefresh(true);
	}

	async function getData(blocks) {
		const dataBlocks = await getDataBlocks(blocks);
		setDataNt({
			kcals: dataBlocks.kcals,
			proteins: dataBlocks.proteins,
			carbohydrates: dataBlocks.carbohydrates,
			fats: dataBlocks.fats,
			fiber: dataBlocks.fiber
		})
		setDataBlocks(dataBlocks.data);
		setRefresh(true);
	}

    function changeStructure(structureId) {
        getStructureById(structureId)
			.then((res) => {
				if (res.status === 200) {
					let structureData = res.data;

					if (structureData?.block?.length > 0) {
						for (
							let i = 0;
							i < structureData?.block?.length;
							++i
						) {
							structureData.block[i].id = i;
						}
					}

					saveDiet(res.data)
					setStructure(res.data);
					setBlocks(res.data.blocks)
					disableLoadingData();
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get structure.",
				});
				//history.push("/structures");
			});
    }

	function actualizeCompetition(selected) {
		if (userId) actualizeTramsData(selected);
		const newCompetition = {
			...competition,
			competitionId: selected.competitionId,
			competitionName: selected.competitionName
		}
		setCompetition(newCompetition);
		actualizeTramsData(newCompetition);
		setChanges(changes+1);
		setRefresh(true)
	}

    if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<Dialog disableBackdropClick={true}
				fullWidth={true}
				open={open}
				onClose={() => setOpen(false)}
				maxWidth="lg"
				aria-labelledby="table-dialog"
			>
				<DialogTitle id="table-dialog">{title}</DialogTitle>
				<DialogContent>
					<Card>
						<CardBody>
							<ConfirmDialog
								title={
								"Are you sure you want to change the structure? You will lose all your changes"
								}
								open={openConfirmDialog === 1}
								setOpen={setOpenConfirmDialog}
								onConfirm={() => {
									changeStructure(selectedStructure._id);
									setRefresh(true);
									setOpenConfirmDialog(-1);
								}}
							/>

							<ConfirmDialog
								title={
								"Are you sure you want to change the competition? You will lose all your changes"
								}
								open={openConfirmDialog === 2}
								setOpen={setOpenConfirmDialog}
								onConfirm={() => {
									actualizeCompetition(selectedCompetition)
									setOpenConfirmDialog(-1);
								}}
							/>

							<br />
							<h3> Diet of the day (provisional) </h3>
							
							<MyAutocomplete
								options={ structuresOptions }
								getOptionLabel={(option) =>
									option.name
								}
								value={ structuresOptions.find(option => option._id === structure?._id) || null }
								onChange={(event, selected) => {
									if (structureHasChanged && structure?.blocks?.length !== 0 && structure) {
										setOpenConfirmDialog(1)
										setSelectedStructure(selected);
									} else {
										changeStructure(selected._id);
										setRefresh(true);
									}
								}}
								placeholder="Select structure"
								label={"Type of structure"}
							/>

							<Row>
								<Col sm={1}/>
								<Col sm={2}>
									<TextField
										id={`kcals`}
										label="kcals"
										value={formatFloat(dataNt?.kcals || 0)}
										InputLabelProps={{
											shrink: true,
											min: 0,
										}}
										margin="normal"
										variant="standard"
										type="number"
										className='readonly'
									/>
								</Col>
								<Col sm={2}>
									<TextField
										id={`carbohydrates`}
										label="carbohydrates"
										value={formatFloat(dataNt?.carbohydrates || 0)}
										InputLabelProps={{
											shrink: true,
											min: 0,
										}}
										margin="normal"
										variant="standard"
										type="number"
										className='readonly'
									/>
								</Col>
								<Col sm={2}>
									<TextField
										id={`proteins`}
										label="proteins"
										value={formatFloat(dataNt?.proteins || 0)}
										InputLabelProps={{
											shrink: true,
											min: 0,
										}}
										margin="normal"
										variant="standard"
										type="number"
										className='readonly'
									/>
								</Col>
								<Col sm={2}>
									<TextField
										id={`fiber`}
										label="fiber"
										value={formatFloat(dataNt?.fiber || 0)}
										InputLabelProps={{
											shrink: true,
											min: 0,
										}}
										margin="normal"
										variant="standard"
										type="number"
										className='readonly'
									/>
								</Col>
								<Col sm={2}>
									<TextField
										id={`fats`}
										label="fats"
										value={formatFloat(dataNt?.fats) || 0}
										InputLabelProps={{
											shrink: true,
											min: 0,
										}}
										margin="normal"
										variant="standard"
										type="number"
										className='readonly'
									/>
								</Col>
								<Col sm={1}/>
							</Row>
							
							<CardBody>
								<Row>
									<Col><h3>Structure</h3></Col><Col/><Col /><Col/>
									<Col>
										<button
											type="button"
											className="btn btn-primary"
											onClick={() => {
												setOpenEditBlockDialog(true);
												setSelectedBlock(null);
												setIsMeal(false);
											}}
										>
											Add exercise
										</button>
									</Col>
									<Col>
										<button
											type="button"
											className="btn btn-primary"
											style={{marginLeft: 10}}
											onClick={() => {
												setOpenEditBlockDialog(true);
												setSelectedBlock(null);
												setIsMeal(true)
											}}
										>
											Add meal
										</button>
									</Col>
								</Row>
							</CardBody>
							{!refresh &&
								blocks?.length > 0 && (
									<Table
										columns={blockColumns}
										data={dataBlocks}
									/>
								)}
						</CardBody>
						
						<Row>
							<Col>
								<TextField
									id={`notes`}
									label="notes"
									InputLabelProps={{
										shrink: true,
									}}
									onChange={handleChange('notes', false)}
									value={userDiet?.notes}
									margin="normal"
									variant="standard"
									placeholder="Enter patient notes..."
									type="text"
								/>

							</Col>
						</Row>
						<Row>
							<Col>
								<FormControlLabel
									control={
										<Checkbox
											checked={userDiet?.notesVisible}
											onChange={() =>
												setUserDiet({
													...userDiet,
													notesVisible: !userDiet?.notesVisible
												})
											}
											name="checkActive"
										/>
									}
									label="Notes visible for the patient"
								/>
							</Col>
						</Row>

						<Row>
							<Col>
								<TextField
									id={`gastrointestinalSymptoms`}
									label="gastrointestinalSymptoms (1 - 5)"
									value={userDiet?.gastrointestinalSymptoms}
									InputLabelProps={{
										shrink: true,
										min: 0,
									}}
									margin="normal"
									placeholder="Not defined by the patient"
									variant="standard"
									required
									type="number"
									className='readonly'
								/>
							</Col>
						</Row>

						<Row>
							<Col>
								<MyAutocomplete
									options={ competitionOptions }
									getOptionLabel={(option) =>
										option.competitionName
									}
									value={ competitionOptions.find(option => option.competitionId === competition?.competitionId) || null }
									onChange={(event, selected) => {
										if (!competition?.competitionId || competition?.competitionId === -1) actualizeCompetition(selected);
										else {
											setOpenConfirmDialog(2)
											setSelectedCompetition(selected);
										}
									}}
									placeholder="Select competition"
									label={"Type of competition"}
								/>
							</Col>
						</Row>
						
						{ competition?.competitionName && competition?.competitionId !== -1 && (
							<Card>
								<CardBody>
									<Row>
										<Col><h4>Trams</h4></Col><Col/><Col/>
										<Col />
									</Row>
									<br/>
									{!refresh && (
										<Table 
											columns={tramsTable}
											data={getTramsTableData(tramsXuser)}
										/>
									)}
									<Row>
										<Col>
											<FormControlLabel
												control={
													<Checkbox
														checked={competition?.finished}
														onChange={() =>
															setCompetition({
																...competition,
																finished: !competition?.finished,
															})
														}
														name="checkActive"
													/>
												}
												label="Finished"
											/>
										</Col>
									</Row>
								</CardBody>
							</Card>
						)}
						
						<EditPatientTramIntakeDialog 
							open={openEditTramUserDialog}
							setOpen={setOpenEditTramUserDialog}
							data={selectedTram}
							userId={userId}
							competitionId={competition?.competitionId}

							onSave={(intakeTram) => {
								let newTrams = tramsXuser;

								const index = tramsXuser?.findIndex((x) => x.numTram === intakeTram.numTram);
								if (index === -1) newTrams.push(intakeTram);
								else newTrams[index] = intakeTram;
								
								setTramsXuser(newTrams);
								setRefresh(true);
							}}
						/>
					
					</Card>
				</DialogContent>
				<DialogActions>
				<Button
					variant="outlined"
					style={{ marginRight: "15px" }}
					onClick={() => {
						setOpen(false);
						const dataAux = _.cloneDeep(data);
						setUserDiet(dataAux);
						setDataNt({kcals: 0, carbohydrates: 0, proteins: 0, fats: 0, fiber: 0});
						setStructure(dataAux?.diet);
						setBlocks(dataAux?.diet?.blocks);
					}}
				>
					Close
				</Button>
				<Button
					variant="outlined"
					color="primary"
					onClick={() => {
						//setOpen(false);
						onSave(userDiet, tramsXuser, competition);
						setDataNt({kcals: 0, carbohydrates: 0, proteins: 0, fats: 0, fiber: 0});
						//setUserDiet(null);
					}}
				>
					Save
				</Button>
				</DialogActions>
			</Dialog>
		);
}
