import { authClient, API } from '../index'

// Get all users
export const getUsers = () => {
  return authClient().get(`${API}/user?getDisabled=true`)
}

// Get all patients
export const getPatients = () => {
  return authClient().get(`${API}/user?role=patient&getDisabled=true`)
}

// Get user by id
export const getUserById = (id) => {
  return authClient().get(`${API}/user/${id}`)
}

// Delete user
export const deleteUser = (id) => {
  return authClient().delete(`${API}/user/${id}`)
}

// Create user
export const postUser = async (user) => {
  return authClient().post(`${API}/user`, user)
}

// Update user
export const updateUser = async (id, user) => {
  return authClient().put(`${API}/user/${id}`, user)
}

// Count all admins
export const countAdmins = () => {
  const role = "admin"
	return authClient().get(`${API}/user/count/${role}`)
}

// Count all patients
export const countPatients = () => {
  const role = "patient"
	return authClient().get(`${API}/user/count/${role}`)
}

// Change status user
export const changeStatusUser = async (id, active) => {
	return authClient().put(`${API}/user/change-status/${id}`, { active });
};
