import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { alertError } from "../../../../utils/logger";
import MultilanguageTabBlock from "../../MultilanguageTabBlock";
import MyAutocomplete from "../../MyAutocomplete";
import { getFoodById } from "../../../../api/food";

function getEmptyFood() {
	return {
        food: null,
        name: null,
        quantity: null,
        number: null,
        observations: "",
        grams: 0,
        selectedPortion: -1,
	};
}

const EditTramFoodDialog = (props) => {
	const { title, data, open, setOpen, onSave, foodOptions } = props;

	const [food, setFood] = useState(null);
    const [completeFood, setCompleteFood] = useState(null);
    const [portionOptions, setPortionOptions] = useState([]);
	const [selectedPortion, setSelectedPortion] = useState({_id: -1});

	const [refresh, setRefresh] = useState(false);

	useEffect(() => {
		if (!open || !data) setFood(getEmptyFood());
		else {
            setSelectedPortion({_id: data.selectedPortion})
            setFood(data);
            if (data?.food)
                getFoodById(data?.food)
                    .then((res) => {
                        // setFood(res.data);
                        setCompleteFood(res.data);
                        actualizePortionOptions(res.data);
                    })
                    .catch((error) => {
                        alertError({
                            error: error,
                            customMessage: "Could not get food"
                        })
                        setOpen(false);
                    })
		}
	}, [data, open]);

    useEffect(() => {
        setRefresh(false)
    }, [refresh])

    useEffect(() => {
		if (selectedPortion?._id !== -1 && completeFood?.portions?.length > 0) {
			const portionComplete = completeFood?.portions?.find((x) => x._id === selectedPortion?._id);
			setFood({...food, grams: portionComplete?.grams * food?.quantity});
		}
	}, [selectedPortion, food?.quantity])

    function actualizePortionOptions(food) {
		let data = [];

		if (food?.portions?.length > 0) {
			const portions = food.portions;
			data.push({
				_id: -1,
				name: "No portion"
			});
			for (let i = 0; i < portions.length; ++i) {
				let elem = {};
				const act = portions[i];
				
				elem._id = i;
				elem.name = act?.name;
				
				data.push(elem);
			}
		}

		setPortionOptions(data);
	}

	const handleChange = (element, lang) => (event) => {
		if (event.target.value === " ") return;
        else {
			if (!food[element]) food[element] = {};
			let newText = food[element];
			newText = event.target.value;
			setFood({ ...food, [element]: newText });

		} 

        setRefresh(true);
	};

	const renderMultilanguageTabContent = (lang) => {
		return (
			<>
				<br />
			</>
		);
	};

    return (
		<Dialog disableBackdropClick={true} 
			fullWidth={true}
			open={open}
			onClose={() => setOpen(false)}
			maxWidth="lg"
			aria-labelledby="table-dialog"
		>
			<DialogTitle id="table-dialog">{title}</DialogTitle>
			<DialogContent>
				<MultilanguageTabBlock
					multilanguageTabContent={renderMultilanguageTabContent}
				/>
				<br />
                <MyAutocomplete
                    options={ foodOptions }
                    getOptionLabel={(option) =>
                        option.name
                    }
                    value={ foodOptions.find(option => option._id === food?.food) || null }
                    onChange={(event, selected) => {
                        setFood({
                            ...food,
                            name: selected.name,
                            food: selected._id
                        })
                        setRefresh(true)
                    }}
                    placeholder="Select food"
                    label={"Type of food"}
                />
                <Row>
                    <Col sm={8}>
                        <MyAutocomplete
                            options={ portionOptions }
                            getOptionLabel={(option) => option.name}
                            value={
                                portionOptions?.find(
                                    (x) => x._id === selectedPortion?._id
                                ) || null
                            }
                            onChange={(event, selected) => {
                                setSelectedPortion(selected)
                                setRefresh(true);
                            }}
                            placeholder="Select portion"
                            label={"Portion"}
                        />
                    </Col>
                    <Col sm={2}>
                        <TextField
                            id={`quantity`}
                            label="Quantity"
                            value={food?.quantity}
                            onChange={handleChange("quantity")}
                            InputProps={{
                                inputProps: {
                                    min: 0
                                }
                            }}
                            inputProps={{ readOnly: (selectedPortion?._id < 0)? true : false}}
                            margin="normal"
                            variant="standard"
                            placeholder="Enter quantity..."
                            required
                            type="number"
                        />
                    </Col>
                    <Col sm={2}>
                        <TextField
                            id={`grams`}
                            label="Grams"
                            value={food?.grams}
                            onChange={handleChange("grams")}
                            InputProps={{
                                inputProps: {
                                    min: 0
                                }
                            }}
                            inputProps={{ readOnly: (selectedPortion?._id >= 0)? true : false}}
                            margin="normal"
                            variant="standard"
                            placeholder="Enter grams..."
                            className={(selectedPortion?._id >= 0)? 'readonly' : ''}
                            required
                            type="number"
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <TextField
                            id={`observation`}
                            label="Observation"
                            value={food?.observations}
                            onChange={handleChange("observations")}
                            InputLabelProps={{
                                shrink: true
                            }}
                            margin="normal"
                            variant="standard"
                            placeholder="Enter observation..."
                            required
                            type="text"
                        />
                </Col>
                </Row>
			</DialogContent>
			<DialogActions>
				<Button
					variant="outlined"
					style={{ marginRight: "15px" }}
					onClick={() => {
						setOpen(false);
						setFood(getEmptyFood());
					}}
				>
					Close
				</Button>
				<Button
					variant="outlined"
					color="primary"
					onClick={() => {
                        if (!food?.name) {
                            alertError({
                                error: null,
                                customMessage: "The food is required.",
                            });
                        } else {
                            setOpen(false);
                            onSave({
                                ...food,
                                selectedPortion: selectedPortion?._id
                            });
                            setSelectedPortion({_id: -1})
                            setFood(getEmptyFood());
                        }
					}}
				>
					Save
				</Button>
			</DialogActions>
		</Dialog>
	);
};
export default EditTramFoodDialog;