import {
	TextField,
	createMuiTheme,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React from "react";

export default function MyAutocomplete(props) {
	const { options, getOptionLabel, value, onChange, placeholder, label } = props;
	
    return (
        <>
            <Autocomplete
                filterSelectedOptions
                disablePortal
                disableClearable
                options={ options }
                getOptionLabel={(option) =>
                    getOptionLabel(option)
                }
                value={ value }
                onChange={(event, selected) => {
                    onChange(event, selected)
                }}
                renderInput={(params) => (
                <TextField
                    {...params}
                    margin="normal"
                    variant="standard"
                    InputLabelProps={{
                    shrink: true,
                    }}
                    required
                    placeholder={placeholder}
                    label={label}
                />
                )}
            />
        </>
    );
}
