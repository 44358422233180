import {
	Button,
	Checkbox,
	FormControlLabel,
	MuiThemeProvider,
	TextField,
	Tooltip,
	createMuiTheme,
} from "@material-ui/core";
import { ArrowDownward, ArrowUpward, Delete, Edit, Visibility } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { SERVER_URL } from "../../../../api";
import {
	deleteStructure,
	getStructureById,
	postStructure,
	updateStructure,
} from "../../../../api/structure";
import { checkIsEmpty, formatFloat } from "../../../../utils/helpers";
import { alertError, alertSuccess } from "../../../../utils/logger";
import MultilanguageTabBlock from "../../../components/MultilanguageTabBlock";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import EditStructureBlockDialog from "../../../components/dialogs/structures/EditStructureBlockDialog";
import PreviewDialog from "../../../components/dialogs/PreviewDialog";
import Table, {
	buttonsStyle,
} from "../../../components/tables/table";
import { useSkeleton } from "../../../hooks/useSkeleton";
import { Row, Col } from 'react-bootstrap';
import Editor from "../../../components/editor/Editor";
import { blockCalc } from "../../../../utils/structuresCalcs";

// Create theme for delete button (red)
const theme = createMuiTheme({
	palette: {
		secondary: {
			main: "#F64E60",
		},
	},
});

function getEmptyStructure() {
	return {
		fullName: {},
		description: {},
		kcal: 0,
		carbohydrates: 0,
		protein: 0,
		fats: 0,
		fatskg: 0,
		fatspercent: 0,
		blocks: [],
		imageURL: null,
		active: true,
	};
}

export default function EditStructuresPage() {
	const [structure, setStructure] = useState(getEmptyStructure());
	const [openConfirmDialog, setOpenConfirmDialog] = useState(0);
	const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
	const [selectedImage, setSelectedImage] = useState(null);

	const [openEditBlockDialog, setOpenEditBlockDialog] = useState(false);
	const [openViewBlockDialog, setOpenViewBlockDialog] = useState(false);
	const [isMeal, setIsMeal] = useState(false);
	const [blocks, setBlocks] = useState([]);
	const [selectedBlock, setSelectedBlock] = useState(null);

	const [refresh, setRefresh] = useState(false);
	const [changes, setChanges] = useState(-2);

	const structureId = useParams().id;
	const history = useHistory();
	const user = useSelector(
		(store) => store.authentication?.user,
		shallowEqual
	);

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	function typeFormatter(cell) {
		const block = blocks.find((x) => cell === x.number);
		return (block?.isIntake? block?.intake.isSnack? "Snack" : "Main" : "Exercise");
	}
	
	function blockMove(block, index, newIndex) {
		let blocks = [
			...structure.blocks
		]
		const blockUp = blocks.find(
			(x) => x.number === newIndex
		)
		
		blocks[newIndex] = {
			...block,
			number: newIndex
		};
		blocks[index] = {
			...blockUp,
			number: index
		}

		reCalcNutritionalValues({
			...structure,
			blocks: blocks
		});
		setBlocks(blocks);
		setRefresh(true);
	}

	async function openData(block) {
		setSelectedBlock(block);
	}

	useEffect(() => {
		if (selectedBlock) setOpenEditBlockDialog(true);
	}, [selectedBlock])

	useEffect(() => {
		if (!openEditBlockDialog) setSelectedBlock(null);
	}, [openEditBlockDialog])

	function blocksButtonFormatter(cell) {
		const index = blocks.findIndex((x) => x.number === cell);
		const block = blocks[index];

		return (
			<>
				<Tooltip title="Edit">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
							openData(block);
							// setOpenEditBlockDialog(true);
							// setSelectedBlock(block);
							setIsMeal(block.isIntake);
						}}
					>
						<Edit />
					</Button>
				</Tooltip>
				<Tooltip title="Move up">
					<Button
						size="small"
						style={buttonsStyle}
						disabled={index === 0}
						onClick={() => blockMove(block, index, index-1)}
					>
						<ArrowUpward />
					</Button>
				</Tooltip>
				<Tooltip title="Move down">
					<Button
						size="small"
						disabled={
							index >= structure.blocks?.length - 1
						}
						style={buttonsStyle}
						onClick={() => blockMove(block, index, index+1)}
					>
						<ArrowDownward />
					</Button>
				</Tooltip>
				<Tooltip title="Delete">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
							let newBlocks = blocks;
							newBlocks.splice(index, 1);

							reCalcNutritionalValues({
								...structure,
								blocks: blocks
							});
							setBlocks(newBlocks);
							setRefresh(true);
						}}
					>
						<Delete />
					</Button>
				</Tooltip>
			</>
		)
	}

	useEffect(() => {
		if (!structureId) {
			disableLoadingData();
			return;
		}
		getStructureById(structureId)
			.then((res) => {
				if (res.status === 200) {
					let structureData = res.data;

					if (structureData?.block?.length > 0) {
						for (
							let i = 0;
							i < structureData?.block?.length;
							++i
						) {
							structureData.block[i].id = i;
						}
					}

					reCalcNutritionalValues({...res.data})
					setBlocks(res.data.blocks)
					disableLoadingData();
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get structure.",
				});
				history.push("/structures");
			});
	}, [structureId, disableLoadingData, history]);

	useEffect(() => {
		setRefresh(false);
	}, [refresh])

	useEffect(() => {
		if (!structureId) setChanges(changes+2);
		else setChanges(changes+1);
	}, [structure])

	function saveStructure() {
		let saveStructure = structure;

		if (saveStructure?.block?.length > 0) {
			saveStructure.block.forEach((block) => {
				delete block.id;
			});
		}

		if (checkIsEmpty(structure.fullName))
			return alertError({
				error: null,
				customMessage:
					"The name is required in at least one of the languages.",
			});
		if (!structureId) {
			postStructure(structure, selectedImage)
				.then((res) => {
					if (res.status === 201) {
						alertSuccess({
							title: "Saved!",
							customMessage: "Structure successfully created.",
						});
						history.push("/structures");
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not save structure.",
					});
				});
		} else {
			updateStructure(structureId, structure, selectedImage)
				.then((res) => {
					if (res.status === 200) {
						alertSuccess({
							title: "Saved!",
							customMessage: "Changes successfully saved.",
						});
						history.push("/structures");
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not save changes.",
					});
				});
		}
	}

	const handleChange = (element, lang) => (event) => {
		if (event.target.value === " ") return;
		if (lang) {
			if (!structure[element]) structure[element] = {};
			let newText = structure[element];
			newText[lang] = event.target.value;
			setStructure({ ...structure, [element]: newText });
		} else
			setStructure({
				...structure,
				[element]: event.target.value,
			});
	};

	const handleChangeEditor = (element, lang, value) => {
		if (lang) {
			if (value === " ") return;
			if (!structure[element]) structure[element] = {};
			let newText = structure[element];
			newText[lang] = value;
			setStructure({ ...structure, [element]: newText });
		} else setStructure({ ...structure, [element]: value });
	};

	const renderMultilanguageTabContent = (lang) => {
		return (
			<>
				<br />
				<TextField
					id={`fullName`}
					label="Full name"
					value={
						(structure.fullName && structure.fullName[lang]) || ""
					}
					onChange={handleChange("fullName", lang)}
					InputLabelProps={{
						shrink: true,
					}}
					margin="normal"
					variant="standard"
					required
				/>
				<br />
				<Editor
					body={(structure.description && structure.description[lang]) || ""}
					setBody={(new_body) =>
						handleChangeEditor("description", lang, new_body)
					}
					className="max-height"
					lang={lang}
					placeholder={"Enter structure description here..."}
					name="Description"
				/>
			</>
		);
	};

	function titleFormatter(cell) {
		const block = structure?.blocks.find((x) => cell === x.number);
		if (block?.intake?.optional) return block?.name + " (Optional)";
		else return block?.name;
	}

	const blockColumns = [
		{
			dataField: "number",
			text: "title",
			formatter: titleFormatter
		},
		{
			dataField: "number",
			text: "type",
			formatter: typeFormatter
		},
		{
			dataField: "number",
			text: "",
			formatter: blocksButtonFormatter,
			align: 'right',
			headerStyle: { width: '200px'}
		}
	];

	function getDataBlocks(blocks) {

		function sortByNumber(a, b) {
			if (a.number < b.number) return -1;
			if (a.number > b.number) return 1;
			return 0;
		}

		let data = [];

		for (let i = 0; i < blocks.length; ++i) {
			let elem = {};
			const block = blocks[i];

			elem._id = block._id;
			elem.number = block.number;

			data.push(elem);
		}

		data.sort(sortByNumber);

		return data;
	}

	async function reCalcNutritionalValues(structure) {
		const blocks = structure?.blocks;
		let kcals = 0;
		let carbohydrates = 0;
		let protein = 0;
		let fats = 0;
		let fatskg = 0;
		let fatspercent = 0;

		for (let i = 0; i < blocks.length; ++i) {
			const block = blocks[i];
			const calcs = await blockCalc(block);

			if (block?.isIntake) {
				if (block?.intake?.optional) {
					let kcalsSum = calcs?.kcals;
					let carbohydratesSum = calcs?.carbohydrates;
					let proteinSum = calcs?.proteins;
					let fatsSum = calcs?.fats;
					let counter = 1;
					
					for (let j = i+1; j < blocks?.length; ++j) {
						const actBlock = blocks[j];
						if (actBlock?.intake?.optional) {
							const calcs = await blockCalc(actBlock);
							kcalsSum += calcs?.kcals;
							carbohydratesSum += calcs?.carbohydrates;
							proteinSum += calcs?.proteins;
							fatsSum += calcs?.fats;
							counter++;
						} else break;
					}

					kcals += kcalsSum/counter;
					carbohydrates += carbohydratesSum/counter;
					protein += proteinSum/counter;
					fats += fatsSum/counter;
					i += counter-1;
				} else {
					kcals += calcs?.kcals;
					carbohydrates += calcs?.carbohydrates;
					protein += calcs?.proteins;
					fats += calcs?.fats;
				}
			} else {
				kcals = kcals - calcs?.kcals;
			}
		}

		setStructure({
			...structure,
			kcal: kcals,
			carbohydrates: carbohydrates,
			protein: protein,
			//fiber: fiber,
			fats: fats,
			fatspercent: fatspercent,
			fatskg: fatskg
		});
		setRefresh(true);
	}

	if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<>
				<Card>
				<CardHeader title={structure?._id?'Structure: ' + (structure.fullName?structure.fullName.es:''):'New structure'}>
					<div className="mt-5">
						<Button
							onClick={() => {
								if (changes > 0) setOpenConfirmDialog(1);
								else history.push("/structures");
							}}
							variant="outlined"
							style={{ marginRight: "20px" }}
						>
							Back
						</Button>
						<Button
							onClick={() => saveStructure()}
							variant="outlined"
							color="primary"
							style={{ marginRight: "20px" }}
						>
							Save structure
						</Button>
						<ConfirmDialog
							title={
								"Are you sure you want to go back? You will lose all your changes"
							}
							open={openConfirmDialog === 1}
							setOpen={setOpenConfirmDialog}
							onConfirm={() => {
								history.push("/structures")
							}}
						/>
						{structureId && user?.role.includes("admin") && (
							<>
								<MuiThemeProvider theme={theme}>
									<Button
										onClick={() => setOpenConfirmDialog(2)}
										variant="outlined"
										color="secondary"
										style={{ marginRight: "20px" }}
									>
										Delete structure
									</Button>
									<div
										style={{
											display: "flex",
											flexDirection: "row",
											marginLeft: "auto",
										}}
									></div>
								</MuiThemeProvider>

								<ConfirmDialog
									title={
										"Are you sure you want to delete this structure?"
									}
									open={openConfirmDialog === 2}
									setOpen={setOpenConfirmDialog}
									onConfirm={() => {
										deleteStructure(structureId)
											.then((res) => {
												if (
													res.status === 204 ||
													res.status === 200
												) {
													alertSuccess({
														title: "Deleted!",
														customMessage:
															"Structure deleted successfully",
													});
													history.push("/structures");
												}
											})
											.catch((error) => {
												alertError({
													error: error,
													customMessage:
														"Could not delete structure.",
												});
											});
									}}
								/>
							</>
						)}
					</div>
				</CardHeader>
					<CardBody>
						<MultilanguageTabBlock
							multilanguageTabContent={
								renderMultilanguageTabContent
							}
						/>
						<br />
						<Row>
							<Col sm={2}>
								<TextField
									id={`kcal`}
									label="KCal"
									value={formatFloat(structure.kcal)}
									InputLabelProps={{
									shrink: true
									}}
									margin="normal"
									variant="standard"
									required
									type="number"
									className='readonly'
								/>
							</Col>
							<Col sm={2}>
								<TextField
									id={`carbohydrates`}
									label="Carbohydrates"
									value={formatFloat(structure.carbohydrates)}
									InputLabelProps={{
									shrink: true
									}}
									margin="normal"
									variant="standard"
									required
									type="number"
									className='readonly'
								/>
							</Col>
							<Col sm={2}>
								<TextField
									id={`protein`}
									label="Protein"
									value={formatFloat(structure.protein)}
									InputLabelProps={{
									shrink: true
									}}
									margin="normal"
									variant="standard"
									required
									type="number"
									className='readonly'
								/>
							</Col>
							<Col sm={2}>
								<TextField
									id={`fats`}
									label="Fats"
									value={formatFloat(structure.fats)}
									InputLabelProps={{
									shrink: true
									}}
									margin="normal"
									variant="standard"
									required
									type="number"
									className='readonly'
								/>
							</Col>
							<Col sm={2}>
								<TextField
									id={`fatskg`}
									label="Fats Kg"
									value={formatFloat(structure.fatskg)}
									InputLabelProps={{
									shrink: true
									}}
									margin="normal"
									variant="standard"
									required
									type="number"
									className='readonly'
								/>
							</Col>
							<Col sm={2}>
								<TextField
									id={`fatspercent`}
									label="Fats percent"
									value={formatFloat(structure.fatspercent)}
									InputLabelProps={{
									shrink: true
									}}
									margin="normal"
									variant="standard"
									required
									type="number"
									className='readonly'
								/>
							</Col>
						</Row>
						<br></br>
						<label htmlFor={"upload-image"} className="ml-0">
							<input
								style={{ display: "none" }}
								id={"upload-image"}
								name={"upload-image"}
								type="file"
								accept={"image/*"}
								onChange={(e) => {
									setSelectedImage(e.target.files[0]);
								}}
							/>
							<Button
								style={{ marginRight: "15px" }}
								color="secondary"
								component="span"
								variant="standard"
							>
								{selectedImage ||
								(structure.imageURL &&
									structure.imageURL !== "")
									? "Change image"
									: "Upload image"}
							</Button>
						</label>
						{(selectedImage ||
							(structure.imageURL &&
								structure.imageURL !== "")) && (
							<>
								<Tooltip title={"Preview image"}>
									<Button
										size="small"
										onClick={() =>
											setOpenPreviewDialog(true)
										}
										style={{
											...buttonsStyle,
											marginRight: "15px",
										}}
									>
										<Visibility />
									</Button>
								</Tooltip>
								<PreviewDialog
									title={"Preview image"}
									open={openPreviewDialog}
									setOpen={setOpenPreviewDialog}
									src={
										selectedImage
											? URL.createObjectURL(selectedImage)
											: `${SERVER_URL}/${structure.imageURL}`
									}
								/>
								<span>
									{selectedImage
										? selectedImage?.name
										: structure.imageURL &&
										  structure.imageURL !== ""
										? structure.imageURL?.split(
												/-(.*)/s
										  )[1]
										: ""}
								</span>
								<Tooltip title={"Delete image"}>
									<Button
										size="small"
										onClick={() => {
											setSelectedImage(null);
											setStructure({
												...structure,
												imageURL: null,
											});
										}}
										style={{
											...buttonsStyle,
											marginRight: "15px",
										}}
									>
										<Delete />
									</Button>
								</Tooltip>
							</>
						)}
						<br />
						<FormControlLabel
							control={
								<Checkbox
									checked={structure.active}
									onChange={() =>
										setStructure({
											...structure,
											active: !structure.active,
										})
									}
									name="checkActive"
								/>
							}
							label="Active"
						/>
					</CardBody>
					<CardHeader title="Block">
						<CardHeaderToolbar>
							<button
								type="button"
								className="btn btn-primary"
								onClick={() => {
									setOpenEditBlockDialog(true);
									setSelectedBlock(null);
									setIsMeal(false);
								}}
							>
								Add exercise
							</button>
							<button
								type="button"
								className="btn btn-primary"
								style={{marginLeft: 10}}
								onClick={() => {
									setOpenEditBlockDialog(true);
									setSelectedBlock(null);
									setIsMeal(true)
								}}
							>
								Add meal
							</button>
						</CardHeaderToolbar>
					</CardHeader>
					<CardBody>
						{!refresh &&
							blocks?.length > 0 && (
								<Table
									columns={blockColumns}
									data={getDataBlocks(blocks)}
								/>
							)}
					</CardBody>
					{ openEditBlockDialog ? (
						<>
							<EditStructureBlockDialog
								open={openEditBlockDialog || openViewBlockDialog}
								setOpen={
									openViewBlockDialog
										? setOpenViewBlockDialog
										: setOpenEditBlockDialog
								}
								data={selectedBlock}
								readOnly={openViewBlockDialog}
								meal={isMeal}

								onSave={(block, isMeal) => {
									let newBlocks = (blocks?.length > 0)? [...blocks] : [];

									const index = newBlocks.findIndex(
										(x) => x.number === block.number
									);
									
									if (index !== -1) {
										newBlocks[index] = {
											...block,
										};
									} else {
										newBlocks.push({
											...block,
											number: newBlocks.length
										});
									}

									reCalcNutritionalValues({
										...structure,
										blocks: newBlocks
									});
									setBlocks(newBlocks);
								}}
							/>
						</>
					): <></>}
					
				</Card>
			</>
		);
}
