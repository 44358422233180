import React, { useEffect, useState } from "react";
import {
	Card,
    CardBody
} from "../../../../_metronic/_partials/controls";
import { useSkeleton } from "../../../hooks/useSkeleton";
import _ from 'lodash';
import { Button, Col, Row } from "react-bootstrap";
import { Checkbox, FormControlLabel, TextField, Tooltip } from "@material-ui/core";
import MyAutocomplete from "../../../components/MyAutocomplete";
import { getProductsPatient } from "../../../../api/productsPatient";
import { alertError } from "../../../../utils/logger";
import { getUserProductsByUserId } from "../../../../api/userProducts";
import RowColText from "../../../components/textFields/RowColText";
import OnlyText from "../../../components/textFields/OnlyText";
import ProductsPatientTable from "../../../components/tables/productsPatientTable";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import RowColTextMult from "../../../components/textFields/RowColTextMult";
import OnlyTextMult from "../../../components/textFields/OnlyTextMult";

export default function EditPatientInfo(props) {
	const { patientId, patient, setPatient, changePassword, setChangePassword,
        handleChange, handleChangeSelector, userProducts, setUserProducts,
        newPassword, setNewPassword,
    } = props;

	const [openConfirmDialog, setOpenConfirmDialog] = useState(0);

    const [productsPatient, setProductsPatient] = useState([]);

    const [clinicInfo, setClinicInfo] = useState(false);
    const [lifestyleInfo, setLifestyleInfo] = useState(false);
    const [dietaryInfo, setDietaryInfo] = useState(false);
    const [sportInfo, setSportInfo] = useState(false);
    const [specificSportInfo, setSpecificSportInfo] = useState(false);
	
	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

    useEffect(() => {
        disableLoadingData();
    }, [disableLoadingData])

    const genderOptions = [
        { _id: 'male', fullName: 'Male' },
        { _id: 'female', fullName: 'Female' },
    ];
    const workOptions = [
        { _id: '0', fullName: 'Not working' },
        { _id: '1', fullName: 'Sedentary work (sitting at a computer, driving or doing manual tasks without physical demand, standing without walking much)' },
        { _id: '2', fullName: 'Lightly active work (walking, lifting/carrying light objects, etc.)' },
        { _id: '3', fullName: 'Moderate physical work (requires more continuous or brisk walking, going up and down stairs, carrying heavier objects or maneuvering people frequently, etc.)' },
        { _id: '4', fullName: 'Very heavy physical work (requires a high physical demand, carrying heavy objects, going up and down stairs, etc.)' },
    ];
    const leisureOptions = [
        { _id: '0', fullName: 'Not leisure' },
        { _id: '1', fullName: 'Sedentary leisure (video games, sofa, TV, few domestic activities, etc.)' },
        { _id: '2', fullName: 'Lightly active leisure (domestic activities, walking, outdoor social life, etc.)' },
        { _id: '3', fullName: 'Moderately active leisure (domestic activities, transport on foot or bicycle, active outdoor social life, short time sitting watching TV or using the computer, etc.)' },
    ];

    const backgroundStyle = {backgroundColor: '#A7D971'}

    function OpenInformation(title, arrowTitle, info, setInfo) {
        const arrowStyle = {color: '#E1F2CE'}
        return (
            <>
                <br/>
                    <Row style={backgroundStyle}>
                        <Col sm={11} style={{marginTop: 12}}>
                            <h5 style={{fontWeight: 'bold'}}>{title}</h5>
                        </Col>
                        <Col sm={1}>
                            <Tooltip title={arrowTitle}>
                            <Button
								size="small"
                                style={{backgroundColor: 'transparent', borderColor: 'transparent', marginLeft: 40}}
								onClick={() => setInfo(!info)}
							>
                                {info? (<ExpandLess style={arrowStyle}/>) : 
                                (<ExpandMore style={arrowStyle}/>)}
							</Button>
                            </Tooltip>
                        </Col>
                    </Row>
                <br/>
            </>
        )
    }

	if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<Card>
				<CardBody> 
                    <Row>
                        <Col sm={4} md={2}><OnlyText data={patient} title={"First name"} element={"name"} handleChange={handleChange} /></Col>
                        <Col sm={8} md={3}><OnlyText data={patient} title={"Last name"} element={"surname"} handleChange={handleChange} /></Col>
                        <Col sm={4} md={2}>
                            <TextField
                                id={`birthdate`}
                                label="Birthdate"
                                value={patient?.birthdate}
                                onChange={handleChange('birthdate')}
                                InputLabelProps={{
                                shrink: true
                                }}
                                margin="normal"
                                variant="standard"
                                type='date'
                                required
                            />
                        </Col>
                        <Col sm={2} md={1}>
                            <TextField
                                id={`age`}
                                label="Age"
                                value={patient?.age}
                                InputLabelProps={{
                                shrink: true
                                }}
                                inputProps={{ readOnly: true }}
                                margin="normal"
                                variant="standard"
                                className='readonly'
                            />
                        </Col>
                        <Col sm={6} md={4}>
                            <MyAutocomplete
                                options={genderOptions}
                                getOptionLabel={(option) =>
                                option.fullName
                                }
                                value={genderOptions.find(option => option._id === patient?.gender) || null}
                                onChange={(event, selected) => {
                                setPatient({
                                    ...patient,
                                    gender: selected?._id,
                                });
                                }}
                                placeholder="Select gender"
                                label={"Gender"}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={5}><OnlyText data={patient} title={"Email"} element={"email"} handleChange={handleChange} /></Col>
                        <Col sm={3}><OnlyText data={patient} title={"Phone"} element={"phone"} handleChange={handleChange} /></Col>
                    </Row>
                    <Row>
                        <Col sm>
                        {(patientId && changePassword) ? <>
                            <Row>
                            <Col sm>              
                                <TextField
                                id={`password`}
                                label="Password"
                                value={newPassword.password}
                                onChange={(event) => {
                                    if (event.target.value !== ' ') setNewPassword({ ...newPassword, password: event.target.value })
                                }}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                type="password"
                                margin="normal"
                                variant="standard"
                                />
                            </Col>
                            <Col sm>
                                <TextField
                                id={`repeatPassword`}
                                label="Repeat password"
                                value={newPassword.repeatPassword}
                                onChange={(event) => {
                                    if (event.target.value !== ' ') setNewPassword({ ...newPassword, repeatPassword: event.target.value })
                                }}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                type="password"
                                margin="normal"
                                variant="standard"
                                />
                            </Col>
                            </Row>
                            
                            {patientId && <>
                            <Button
                                onClick={() => {
                                setChangePassword(false)
                                setNewPassword({ password: null, repeatPassword: null })
                                }}
                                variant="standard"
                                style={{ marginRight: '20px' }}
                            >
                                Cancel change password
                            </Button>
                            <br />
                            <br />  
                            </>}
                            </> : <>
                            <br />
                            <Button
                            onClick={() => setChangePassword(true)}
                            variant="standard"
                            color="primary"
                            style={{ marginRight: '20px' }}
                            >
                            Change password
                            </Button>
                        </>}
                        <FormControlLabel
                            control={<Checkbox checked={patient?.active} onChange={() => setPatient({ ...patient, active: !patient?.active })} name="checkActive" />}
                            label="Active"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={patient?.verified} onChange={() => setPatient({ ...patient, verified: !patient?.verified })} name="checkVerified" />}
                            label="Verified"
                        />
                        </Col>
                    </Row>
                    <br />

                    {OpenInformation("CLINIC HISTORY", "Open clinic information", clinicInfo, setClinicInfo)}
                    {clinicInfo && (
                        <>
                            <RowColTextMult data={patient} title={"Diseases / nutrient-specific alterations"} element={"diseases"} handleChange={handleChange}/>
                            <RowColTextMult data={patient} title={"Menstrual cycle"} element={"menstrualCycle"} handleChange={handleChange} />
                            <RowColTextMult data={patient} title={"Sleeping hours and quality"} element={"sleepingH"} handleChange={handleChange} />
                            <br />
                        </>
                    )}

                    {OpenInformation("LIFESTLYE", "Open lifestyle information", lifestyleInfo, setLifestyleInfo)}
                    {lifestyleInfo && (
                        <>
                            <Row>
                                <Col sm={5}>
                                    <OnlyText data={patient} title={"Profession"} element={"profession"} handleChange={handleChange} />
                                </Col>
                                <Col sm={7}>
                                    <OnlyTextMult data={patient} title={"Working hours"} element={"workingHours"} handleChange={handleChange} />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12}>
                                    <MyAutocomplete
                                        options={workOptions}
                                        getOptionLabel={(option) =>
                                        option.fullName
                                        }
                                        value={workOptions.find(option => option._id === patient?.work) || null}
                                        onChange={handleChangeSelector('work')}
                                        placeholder="Select type of job"
                                        label={"Type of job"}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12}>
                                    <MyAutocomplete
                                        options={leisureOptions}
                                        getOptionLabel={(option) =>
                                        option.fullName
                                        }
                                        value={leisureOptions.find(option => option._id === patient?.leisure) || null}
                                        onChange={handleChangeSelector('leisure')}
                                        placeholder="Select type of leisure"
                                        label={"Type of leisure"}
                                    />
                                </Col>
                            </Row>
                            <br />
                        </>
                    )}
                    
                    {OpenInformation("DIETARY HISTORY", "Open dietary information", dietaryInfo, setDietaryInfo)}
                    {dietaryInfo && (
                        <>
                            <Row>
                                <Col sm={2} style={{marginTop: 10}}><h5>Restrictions</h5></Col>
                                <Col sm={1}>
                                    <FormControlLabel
                                        control={<Checkbox checked={patient?.restrMeat} onChange={() => setPatient({ ...patient, restrMeat: !patient?.restrMeat })} name="checkActive" />}
                                        label="Meat"
                                    />
                                </Col>
                                <Col sm={1}>
                                    <FormControlLabel
                                        control={<Checkbox checked={patient?.restrFish} onChange={() => setPatient({ ...patient, restrFish: !patient?.restrFish })} name="checkActive" />}
                                        label="Fish"
                                    />
                                </Col>
                                <Col sm={1}>
                                    <FormControlLabel
                                        control={<Checkbox checked={patient?.restrEggs} onChange={() => setPatient({ ...patient, restrEggs: !patient?.restrEggs })} name="checkActive" />}
                                        label="Eggs"
                                    />
                                </Col>
                                <Col sm={2}>
                                    <FormControlLabel
                                        control={<Checkbox checked={patient?.restrAnimalMilk} onChange={() => setPatient({ ...patient, restrAnimalMilk: !patient?.restrAnimalMilk })} name="checkActive" />}
                                        label="Animal milk"
                                    />
                                </Col>
                            </Row>
                            
                            <RowColTextMult data={patient} title={"Dietary allergies / intolerances"} element={"allergiesIntolerances"} handleChange={handleChange} />
                            <br />
                            <ProductsPatientTable 
                            patientId={patientId}
                            productsPatient={productsPatient}
                            setProductsPatient={setProductsPatient}
                            userProducts={userProducts}
                            setUserProducts={setUserProducts}
                            />
                            <RowColTextMult data={patient} title={"Aversions"} element={"aversions"} handleChange={handleChange} />
                            <RowColTextMult data={patient} title={"Preferences"} element={"preferences"} handleChange={handleChange} />
                            <RowColTextMult data={patient} title={"Supplements"} element={"supplements"} handleChange={handleChange} />
                            <RowColTextMult data={patient} title={"Hunger throughout the day"} element={"hunger"} handleChange={handleChange} />
                            <br/>
                        </>
                    )}
                    
                    {OpenInformation("SPORT HISTORY", "Open sport information", sportInfo, setSportInfo)}
                    {sportInfo && (
                        <>
                            <Row>
                                <Col sm={5}>
                                    <OnlyText data={patient} title={"Main sport"} element={"mainSport"} handleChange={handleChange} />
                                </Col>
                                <Col sm={7}>
                                    <OnlyText data={patient} title={"Secondary sports"} element={"secondarySports"} handleChange={handleChange} />
                                </Col>
                            </Row>
                            <RowColTextMult data={patient} title={"Current standard training week"} element={"trainingWeek"} handleChange={handleChange} />
                            <RowColTextMult data={patient} title={"Training schedule"} element={"trainingSchedule"} handleChange={handleChange} />
                            <br />

                            {OpenInformation("SPORT-SPECIFIC NUTRITION HISTORY", "Open sportSpecific information", specificSportInfo, setSpecificSportInfo)}
                            {specificSportInfo && (
                                <>
                                    <br />
                                            {/* SPORTS TABLE */}
                                    <Row>
                                        <Col xs={2}><h5>Type</h5></Col>
                                        <Col xs={2}><h5>CHO (g/h)</h5></Col>
                                        <Col xs={2}><h5>Water (mL/h)</h5></Col>
                                        <Col xs={6}><h5>Comments</h5></Col>    
                                    </Row>

                                    <Row style={{marginTop: '2px'}}>
                                        <Col xs={2}>Short and easy</Col>
                                        <Col xs={2}>
                                            <TextField
                                                id="shortAndEasyCHO"
                                                style={{width: '100%', height: '50px'}}
                                                label={""}
                                                value={patient["shortAndEasyCHO"]}
                                                onChange={handleChange("shortAndEasyCHO")}
                                                InputLabelProps={{ shrink: true }}
                                                InputProps={{inputProps: {min: 0}}}
                                                margin="none"
                                                variant="standard"
                                                type="number"
                                                required
                                            />
                                        </Col>
                                        <Col xs={2}>
                                            <TextField
                                                id="shortAndEasyWater"
                                                style={{width: '100%', height: '50px'}}
                                                label={""}
                                                value={patient["shortAndEasyWater"]}
                                                onChange={handleChange("shortAndEasyWater")}
                                                InputLabelProps={{ shrink: true }}
                                                InputProps={{inputProps: {min: 0}}}
                                                margin="none"
                                                variant="standard"
                                                type="number"
                                                required
                                            />
                                        </Col>
                                        <Col xs={6}>
                                            <TextField
                                                id="shortAndEasyComments"
                                                style={{width: '100%', height: '50px'}}
                                                label={""}
                                                value={patient["shortAndEasyComments"]}
                                                onChange={handleChange("shortAndEasyComments")}
                                                InputLabelProps={{ shrink: true }}
                                                margin="none"
                                                variant="standard"
                                                type="text"
                                                required
                                                multiline
                                                rows={1}
                                            />
                                        </Col>
                                    </Row>

                                    <Row style={{marginTop: '2px'}}>
                                        <Col xs={2}>High-Intensity</Col>
                                        <Col xs={2}>
                                            <TextField
                                                id="highIntensityCHO"
                                                style={{width: '100%', height: '50px'}}
                                                label={""}
                                                value={patient["highIntensityCHO"]}
                                                onChange={handleChange("highIntensityCHO")}
                                                InputLabelProps={{ shrink: true }}
                                                InputProps={{inputProps: {min: 0}}}
                                                margin="none"
                                                variant="standard"
                                                type="number"
                                                required
                                            />
                                        </Col>
                                        <Col xs={2}>
                                            <TextField
                                                id="highIntensityWater"
                                                style={{width: '100%', height: '50px'}}
                                                label={""}
                                                value={patient["highIntensityWater"]}
                                                onChange={handleChange("highIntensityWater")}
                                                InputLabelProps={{ shrink: true }}
                                                InputProps={{inputProps: {min: 0}}}
                                                margin="none"
                                                variant="standard"
                                                type="number"
                                                required
                                            />
                                        </Col>
                                        <Col xs={6}>
                                            <TextField
                                                id="highIntensityComments"
                                                style={{width: '100%', height: '50px'}}
                                                label={""}
                                                value={patient["highIntensityComments"]}
                                                onChange={handleChange("highIntensityComments")}
                                                InputLabelProps={{ shrink: true }}
                                                margin="none"
                                                variant="standard"
                                                type="text"
                                                required
                                                multiline
                                                rows={1}
                                            />
                                        </Col>
                                    </Row>

                                    <Row style={{marginTop: '2px'}}>
                                        <Col xs={2}>Long</Col>
                                        <Col xs={2}>
                                            <TextField
                                                id="longCHO"
                                                style={{width: '100%', height: '50px'}}
                                                label={""}
                                                value={patient["longCHO"]}
                                                onChange={handleChange("longCHO")}
                                                InputLabelProps={{ shrink: true }}
                                                InputProps={{inputProps: {min: 0}}}
                                                margin="none"
                                                variant="standard"
                                                type="number"
                                                required
                                            />
                                        </Col>
                                        <Col xs={2}>
                                            <TextField
                                                id="longWater"
                                                style={{width: '100%', height: '50px'}}
                                                label={""}
                                                value={patient["longWater"]}
                                                onChange={handleChange("longWater")}
                                                InputLabelProps={{ shrink: true }}
                                                InputProps={{inputProps: {min: 0}}}
                                                margin="none"
                                                variant="standard"
                                                type="number"
                                                required
                                            />
                                        </Col>
                                        <Col xs={6}>
                                            <TextField
                                                id="longComments"
                                                style={{width: '100%', height: '50px'}}
                                                label={""}
                                                value={patient["longComments"]}
                                                onChange={handleChange("longComments")}
                                                InputLabelProps={{ shrink: true }}
                                                margin="none"
                                                variant="standard"
                                                type="text"
                                                required
                                                multiline
                                                rows={1}
                                            />
                                        </Col>
                                    </Row>

                                    <Row style={{marginTop: '2px'}}>
                                        <Col xs={2}>Competition</Col>
                                        <Col xs={2}>
                                            <TextField
                                                id="competitionCHO"
                                                style={{width: '100%', height: '50px'}}
                                                label={""}
                                                value={patient["competitionCHO"]}
                                                onChange={handleChange("competitionCHO")}
                                                InputLabelProps={{ shrink: true }}
                                                InputProps={{inputProps: {min: 0}}}
                                                margin="none"
                                                variant="standard"
                                                type="number"
                                                required
                                            />
                                        </Col>
                                        <Col xs={2}>
                                            <TextField
                                                id="competitionWater"
                                                style={{width: '100%', height: '50px'}}
                                                label={""}
                                                value={patient["competitionWater"]}
                                                onChange={handleChange("competitionWater")}
                                                InputLabelProps={{ shrink: true }}
                                                InputProps={{inputProps: {min: 0}}}
                                                margin="none"
                                                variant="standard"
                                                type="number"
                                                required
                                            />
                                        </Col>
                                        <Col xs={6}>
                                            <TextField
                                                id="competitionComments"
                                                style={{width: '100%', height: '50px'}}
                                                label={""}
                                                value={patient["competitionComments"]}
                                                onChange={handleChange("competitionComments")}
                                                InputLabelProps={{ shrink: true }}
                                                margin="none"
                                                variant="standard"
                                                type="text"
                                                required
                                                multiline
                                                rows={1}
                                            />
                                        </Col>
                                    </Row>

                                    <RowColTextMult data={patient} title={"Products"} element={"products"} handleChange={handleChange} />
                                    <RowColTextMult data={patient} title={"Pre-competition meal"} element={"preCompMeal"} handleChange={handleChange} />
                                    <br />
                                </>
                            )}
                        </>
                    )}

                    <RowColTextMult data={patient} title={"Additional comments"} element={"additionalComments"} handleChange={handleChange} />
                </CardBody>
			</Card>
		);
}
