import {
	Button,
	Checkbox,
	FormControlLabel,
	MuiThemeProvider,
	TextField,
	Tooltip,
	createMuiTheme,
} from "@material-ui/core";
import { Delete, Edit, Visibility } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { SERVER_URL } from "../../../../api";
import { getFoods } from "../../../../api/food";
import {
	deleteFoodPlate,
	getFoodPlateById,
	postFoodPlate,
	updateFoodPlate,
} from "../../../../api/foodPlate";
import { alertError, alertSuccess } from "../../../../utils/logger";
import MultilanguageTabBlock from "../../../components/MultilanguageTabBlock";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import PreviewDialog from "../../../components/dialogs/PreviewDialog";
import Editor from "../../../components/editor/Editor";
import Table, { buttonsStyle } from "../../../components/tables/table";
import { useSkeleton } from "../../../hooks/useSkeleton";
import EditFoodDialog from "../../../components/dialogs/EditFoodDialog";
import { Col, Row } from "react-bootstrap";
import { formatFloat } from "../../../../utils/helpers";
import { recipeCalcs } from "../../../../utils/structuresCalcs";

// Create theme for delete button (red)
const theme = createMuiTheme({
	palette: {
		secondary: {
			main: "#F64E60",
		},
	},
});

function getEmptyFood() {
	return {
		fullName: null,
		grams: '0',
		kcals: 0,
		proteins: 0,
		carbohydrates: 0,
		fats: 0,
		portionConsumed: 0,
		fiber: 0,
	};
}

function getEmptyFoodPlate() {
	return {
		foods: [],
		description: null,
		active: true,
		fullName: null,
		recipe: null,
		totalKcals: 0,
		totalProteins: 0,
		totalCarbohydrates: 0,
		totalFats: 0,
		totalFiber: 0,
		preparationMinutes: null,
		imageURL: null,
	}
}

function getEmptyNutritionalValues() {
	return {
		kcals: 0,
		proteins: 0,
		carbohydrates: 0,
		fats: 0,
		fiber: 0,
	}
}

export default function EditFoodPlatesPage() {
	const [foods, setFoods] = useState([]);
	const [foodPlate, setFoodPlate] = useState(getEmptyFoodPlate());
	const [allFoods, setAllFoods] = useState([]);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(0);
	const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
	const [selectedImage, setSelectedImage] = useState(null);
	const foodPlateId = useParams().id;
	const history = useHistory();
	const user = useSelector(
		(store) => store.authentication?.user,
		shallowEqual
	);

	const [selectedFood, setSelectedFood] = useState(getEmptyFood());
	const [openEditFoodDialog, setOpenEditFoodDialog] = useState(false);
	const [selectedIndexFood, setSelectedIndexFood] = useState(-1);
	const [refresh, setRefresh] = useState(false);
	const [nutritionValues, setNutritionValues] = useState(getEmptyNutritionalValues());

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	const [changes, setChanges] = useState(-4);

	useEffect(() => {
		if (!foodPlateId) setChanges(changes+2);
		else setChanges(changes+1);
		if (foodPlate?.foods?.length > 0) recalcNutrition(foodPlate)
	}, [foodPlate])

	async function recalcNutrition(recipe) {
		const calcs = await recipeCalcs(recipe);
		setNutritionValues(calcs);
	}

	const foodsTable = [
		{
			dataField: "fullName",
			text: "name"
		},
		{
			dataField: "grams",
			text: "grams",
			headerAlign: "center",
			align: "center",
			formatter: gramsFormatter
		},
		{
			dataField: "kcals",
			text: "kcals",
			headerAlign: "center",
			align: "center",
			formatter: numbersFormat,
		},
		{
			dataField: "proteins",
			text: "proteins",
			headerAlign: "center",
			align: "center",
			formatter: numbersFormat,
		},
		{
			dataField: "carbohydrates",
			text: "carbohydrates",
			headerAlign: "center",
			align: "center",
			formatter: numbersFormat,
		},
		{
			dataField: "fats",
			text: "fats",
			headerAlign: "center",
			align: "center",
			formatter: numbersFormat,
		},
		{
			dataField: "fiber",
			text: "fiber",
			headerAlign: "center",
			align: "center",
			formatter: numbersFormat,
		},
		{
			dataField: "_id",
			text: "",
			formatter: foodsButtonFormatter
		}
	]

	function gramsFormatter(cell, row) {
		if (row?.selectedPortion >= 0 && row?.portionQuant >= 0) {
			const portionName = row?.portions?.find((x) => x._id === row.selectedPortion).name;
			return `${row.portionQuant} ${portionName} (${cell}gr)`
		}
		return cell;
	}

	function numbersFormat(cell) { return formatFloat(cell); }

	function foodsButtonFormatter(cell) {
		const index = foods.findIndex((x) => x._id === cell);
		const food = foods.find((x) => x._id === cell);

		return (
			<>
				{cell != 0 && (
					<> 
						<Tooltip title="Edit">
							<Button
								style={buttonsStyle}
								size="small"
								onClick={() => {
									setOpenEditFoodDialog(true);
									setSelectedFood(food)
									setSelectedIndexFood(index)
								}}
							>
								<Edit />
							</Button>
						</Tooltip>
						<Tooltip title="Delete">
							<Button
								style={buttonsStyle}
								size="small"
								onClick={() => {deleteFood(index, food)}}
							>
								<Delete />
							</Button>
						</Tooltip>
					</>
				) 
				}
				
			</>
		)
	}

	function deleteFood(index, food) {
		let newFoods = foods;
		if (index !== -1) newFoods.splice(index, 1);
		setFoods(newFoods);
		setFoodPlate({
			...foodPlate,
			foods: getIdsFoods(newFoods)
		});
		setRefresh(true);
	}

	function getIdsFoods(foods) {
		let data = [];
		
		for (let i = 0; i < foods.length; ++i) {
			let elem = {};
			const actF = foods[i];
			elem.foodId = actF._id;
			elem.grams = actF.grams;
			elem.selectedPortion = actF?.selectedPortion || -1;
			elem.portionQuant = actF?.portionQuant || 0;
			data.push(elem);
		}

		return data;
	}

	useEffect(() => {
		setRefresh(false)
	}, [refresh])

	useEffect(() => {
		if (foodPlate && allFoods.length > 0 && foods.length <= 0) {
			const num = foodPlate.foods.length;
			let data = [];
			for (let i = 0; i < num; ++i) {
				const food = allFoods.find((x) => x._id === foodPlate.foods[i].foodId)
				const actF = foodPlate.foods[i];
				if (food) 
					data.push({
						...food,
						fullName: food.fullName.es,
						grams: actF.grams,
						selectedPortion: actF?.selectedPortion || -1,
						portionQuant: actF?.portionQuant || 0
					});
				else {
					alertError({
						error: "Could not get food plate",
						customMessage: "Could not get food plate.",
					});
				}
			}
			setFoods(getFoodsData(data));
		}
	}, [foodPlate, allFoods]);

	useEffect(() => {
		if (allFoods.length === 0)
			getFoods()
				.then((res) => {
					if (res.status === 200) {
						setAllFoods(res.data)
						disableLoadingData();
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not get food plate."
					})
				})
		if (!foodPlateId) {
			disableLoadingData();
			return;
		}
		getFoodPlateById(foodPlateId)
			.then((res) => {
				if (res.status === 200) {
					let foodPlate = {
						...res.data,
						foods: res?.data?.foods,
					};
					setFoodPlate(foodPlate);
					setRefresh(true);
					disableLoadingData();
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get food plate.",
				});
				history.push("/food-plates");
			});
	}, [foodPlateId, disableLoadingData, history]);

	function saveFoodPlate() {
		if (!foodPlate?.description)
			return alertError({
				error: null,
				customMessage:
					"The descripton is required in at least one of the languages.",
			});
		if (foodPlate?.foods.length === 0)
			return alertError({
				error: null,
				customMessage: "At least one food is required.",
			});

		if (!foodPlateId) {
			postFoodPlate(foodPlate, selectedImage)
				.then((res) => {
					if (res.status === 201) {
						alertSuccess({
							title: "Saved!",
							customMessage: "Food plate successfully created.",
						});
						history.push("/food-plates");
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not save food plate.",
					});
				});
		} else {
			updateFoodPlate(foodPlateId, foodPlate, selectedImage)
				.then((res) => {
					if (res.status === 200) {
						alertSuccess({
							title: "Saved!",
							customMessage: "Changes successfully saved.",
						});
						history.push("/food-plates");
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not save changes.",
					});
				});
		}
	}

	const handleChange = (element, lang) => (event) => {
		if (event.target.value === " ") return;
		if (lang) {
			if (!foodPlate[element]) foodPlate[element] = {};
			let newText = foodPlate[element];
			newText[lang] = event.target.value;
			setFoodPlate({ ...foodPlate, [element]: newText });
		} else
			setFoodPlate({
				...foodPlate,
				[element]: event.target.value,
			});
	};

	const handleChangeEditor = (element, lang, value) => {
		if (lang) {
			if (value === " ") return;
			if (!foodPlate[element]) foodPlate[element] = {};
			let newText = foodPlate[element];
			newText[lang] = value;
			setFoodPlate({ ...foodPlate, [element]: newText });
		} else setFoodPlate({ ...foodPlate, [element]: value });
	};

	function getFoodsData(foods) {
		let data = [];

		for (let i = 0; i < foods?.length; ++i) data.push(getFoodData(foods[i]))
		
		return data;
	}

	function getFoodData(food) {
		const elem = {};

		elem._id = food._id;
		const completeFood = allFoods.find((x) => x._id === food._id);

		elem.fullName = food.fullName;
		elem.portionConsumed = parseFloat(food?.portionConsumed) || 1;

		const grams = parseInt(food.grams) || 0; 
		elem.grams = grams;
		elem.kcals = completeFood.kcals*grams/100;
		elem.proteins = completeFood.proteins*grams/100;
		elem.carbohydrates = completeFood.carbohydrates*grams/100;
		elem.fats = completeFood.fats*grams/100;
		elem.fiber = completeFood.fiber*grams/100;
		elem.portions = completeFood?.portions || [];
		elem.selectedPortion = food?.selectedPortion || -1;
		elem.portionQuant = food?.portionQuant || 0;

		return elem;
	}

	const addNew = () => {
		setSelectedFood(getEmptyFood());
		setOpenEditFoodDialog(true);
		setSelectedIndexFood(-1);
	}

	const renderMultilanguageTabContent = (lang) => {
		return (
			<>
				<br />
				<TextField
					id={`description`}
					label="Title"
					value={
						(foodPlate.description &&
							foodPlate.description[lang]) ||
						""
					}
					onChange={handleChange("description", lang)}
					InputLabelProps={{
						shrink: true,
					}}
					margin="normal"
					variant="standard"
					required
				/>
				<br />
				<Editor
					body={(foodPlate.recipe && foodPlate.recipe[lang]) || ""}
					setBody={(new_body) =>
						handleChangeEditor("recipe", lang, new_body)
					}
					className="max-height"
					lang={lang}
					placeholder={"Enter plate recipe here..."}
					name="Recipe"
				/>
			</>
		);
	};
	
	if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<>
				<Card>
				<CardHeader title={foodPlate?._id?'Recipe: ' + (foodPlate.description?foodPlate.description.es:''):'New recipe'}>
					<div className="mt-5">
						<Button
							onClick={() => {
								if (changes > 0) setOpenConfirmDialog(1);
								else history.push("/food-plates");
							}}
							variant="outlined"
							style={{ marginRight: "20px" }}
						>
							Back
						</Button>
						<Button
							onClick={() => saveFoodPlate()}
							variant="outlined"
							color="primary"
							style={{ marginRight: "20px" }}
						>
							Save food plate
						</Button>
						<ConfirmDialog
							title={
								"Are you sure you want to go back? You will lose all your changes"
							}
							open={openConfirmDialog === 1}
							setOpen={setOpenConfirmDialog}
							onConfirm={() => {
								history.push("/food-plates")
							}}
						/>
						{foodPlateId && user?.role.includes("admin") && (
							<>
								<MuiThemeProvider theme={theme}>
									<Button
										onClick={() => setOpenConfirmDialog(2)}
										variant="outlined"
										color="secondary"
										style={{ marginRight: "20px" }}
									>
										Delete food plate
									</Button>
									<div
										style={{
											display: "flex",
											flexDirection: "row",
											marginLeft: "auto",
										}}
									></div>
								</MuiThemeProvider>

								<ConfirmDialog
									title={
										"Are you sure you want to delete this food plate?"
									}
									open={openConfirmDialog === 2}
									setOpen={setOpenConfirmDialog}
									onConfirm={() => {
										deleteFoodPlate(foodPlateId)
											.then((res) => {
												if (
													res.status === 204 ||
													res.status === 200
												) {
													alertSuccess({
														title: "Deleted!",
														customMessage:
															"Food plate deleted successfully",
													});
													history.push("/food-plates");
												}
											})
											.catch((error) => {
												alertError({
													error: error,
													customMessage:
														"Could not delete food plate.",
												});
											});
									}}
								/>
							</>
						)}
					</div>
				</CardHeader>
					<CardBody>
						<MultilanguageTabBlock
							multilanguageTabContent={
								renderMultilanguageTabContent
							}
						/>
						<br />
						<Row>
							<Col>
								<TextField
									id={`kcals`}
									label="Kcals"
									inputProps={{ readOnly: true }}
									value={formatFloat(nutritionValues?.kcals)}
									margin="normal"
									variant="standard"
									type="number"
									className='readonly'
								/>
							</Col>
							<Col>
								<TextField
									id={`proteins`}
									label="Proteins"
									inputProps={{ readOnly: true }}
									value={formatFloat(nutritionValues.proteins.toFixed(2))}
									margin="normal"
									variant="standard"
									type="number"
									className='readonly'
								/>
							</Col>
							<Col>
								<TextField
									id={`carbohydrates`}
									label="Carbohydrates"
									inputProps={{ readOnly: true }}
									value={formatFloat(nutritionValues.carbohydrates.toFixed(2))}
									margin="normal"
									variant="standard"
									type="number"
									className='readonly'
								/>
							</Col>
							<Col>
								<TextField
									id={`fats`}
									label="Fats"
									inputProps={{ readOnly: true }}
									value={formatFloat(nutritionValues.fats.toFixed(2))}
									margin="normal"
									variant="standard"
									type="number"
									className='readonly'
								/>
							</Col>
							<Col>
								<TextField
									id={`fiber`}
									label="Fiber"
									inputProps={{ readOnly: true }}
									value={formatFloat(nutritionValues.fiber.toFixed(2))}
									margin="normal"
									variant="standard"
									type="number"
									className='readonly'
								/>
							</Col>
						</Row>
						
					</CardBody>
					<CardHeader title="Foods"> 
						<CardHeaderToolbar>
							<button
								type="button"
								className="btn btn-primary"
								onClick={() => addNew()}
							>
								Add new
							</button>
						</CardHeaderToolbar>
					</CardHeader>
					<CardBody>
						{!refresh && (
							<Table
								columns={foodsTable}
								data={foods}
							/>
						)}
					</CardBody>
					<EditFoodDialog
						open={openEditFoodDialog}
						setOpen={setOpenEditFoodDialog}
						data={selectedFood}
						readOnly={false}
						foods={allFoods}
						selectedFoods={foodPlate?.foods}
						onSave={(food) => {
							let newFoods = foods;
							const number = selectedIndexFood;
							const newFood = getFoodData(food);
							
							if (number !== -1) {
								newFoods[number] = newFood;
							}
							else {
								newFoods.push({
									...newFood,
								})
							}
							setFoods(newFoods);
							setFoodPlate({
								...foodPlate,
								foods: getIdsFoods(newFoods)
							});
							setRefresh(true)
						}}
					/>
					<CardBody>
						<br />
						<br />
						<label htmlFor={"upload-image"} className="ml-0">
							<input
								style={{ display: "none" }}
								id={"upload-image"}
								name={"upload-image"}
								type="file"
								accept={"image/*"}
								onChange={(e) => {
									setSelectedImage(e.target.files[0]);
								}}
							/>
							<Button
								style={{ marginRight: "15px" }}
								color="secondary"
								component="span"
								variant="standard"
							>
								{selectedImage ||
								(foodPlate.imageURL &&
									foodPlate.imageURL !== "")
									? "Change image"
									: "Upload image"}
							</Button>
						</label>
						{(selectedImage ||
							(foodPlate.imageURL &&
								foodPlate.imageURL !== "")) && (
							<>
								<Tooltip title={"Preview image"}>
									<Button
										size="small"
										onClick={() =>
											setOpenPreviewDialog(true)
										}
										style={{
											...buttonsStyle,
											marginRight: "15px",
										}}
									>
										<Visibility />
									</Button>
								</Tooltip>
								<PreviewDialog
									title={"Preview image"}
									open={openPreviewDialog}
									setOpen={setOpenPreviewDialog}
									src={
										selectedImage
											? URL.createObjectURL(selectedImage)
											: `${SERVER_URL}/${foodPlate.imageURL}`
									}
								/>
								<span>
									{selectedImage
										? selectedImage?.name
										: foodPlate.imageURL &&
										  foodPlate.imageURL !== ""
										? foodPlate.imageURL?.split(/-(.*)/s)[1]
										: ""}
								</span>
								<Tooltip title={"Delete image"}>
									<Button
										size="small"
										onClick={() => {
											setSelectedImage(null);
											setFoodPlate({
												...foodPlate,
												imageURL: null,
											});
										}}
										style={{
											...buttonsStyle,
											marginRight: "15px",
										}}
									>
										<Delete />
									</Button>
								</Tooltip>
							</>
						)}
						<br />
						<br />
						<FormControlLabel
							control={
								<Checkbox
									checked={foodPlate.active}
									onChange={() =>
										setFoodPlate({
											...foodPlate,
											active: !foodPlate.active,
										})
									}
									name="checkActive"
								/>
							}
							label="Active"
						/>
					</CardBody>
				</Card>
			</>
		);
}
